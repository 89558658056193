import React from "react";
import Icon from "../../assets/components/icon";

export default function(props) {
  const { open } = props;
  return (
    <div className="c-sidebar__brand" style={{ paddingTop: "20px" }}>
      {!open && (
        <Icon
          className="c-sidebar__logo--icon"
          alt="logo"
          name={"V-SMS-vertical"}
          style={{ width: "30px", height: "36px" }}
        />
      )}
      {open && (
        <Icon
          className="c-sidebar__logo--icon"
          alt="logo"
          name={"VYBRNT-SMS-horizontal-white"}
          style={{ width: "100px", height: "36px" }}
        />
      )}
    </div>
  );
}
