import React from "react";
import logger from "./../../../logger";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import MoreIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import toastMaker from "./../../../Common/toastmaker";
import Constants from "./../../../Utils/Constants";

import edit from "./edit.svg";
import certificate from "./certificate.svg";
import Typography from "@material-ui/core/Typography";

import ToolTip from "@material-ui/core/ToolTip";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const CHARACTER_LIMIT = 160;

export default function CustomizedMenus(props) {
  const [open, setOpen] = React.useState(false);
  const [templatetext, setTemplatetext] = React.useState("");
  const [name, setName] = React.useState("");
  const [senderId, setSenderId] = React.useState("");
  const [isMMS, setIsMMS] = React.useState(false);
  const [subject, setSubject] = React.useState("");
  const [file, setFile] = React.useState("");
  const [url, setUrl] = React.useState("");

  const handleDialogOpen = () => {
    setOpen(true);
    //logger.log("Open is:" + open);
  };

  const setFileLocal = e => {
    setUrl(URL.createObjectURL(e[0]))
    setFile(e)
  }

  const handleDialogClose = () => {
    setOpen(false);
    setTemplatetext("");
    setName("");
    setSenderId("");
    setUrl("")
  };

  const handleDialogSubmit = () => {
    logger.log(props);
    if (
      !templatetext ||
      templatetext.length === 0 ||
      !name ||
      name.length === 0 ||
      !senderId ||
      senderId.length === 0
    ) {
      toastMaker.makeToast("All fields are mandatory!", {
        variant: "error",
      });
      return;
    }
    // if (senderId.length > 11) {
    if (senderId.length > 15) {
      toastMaker.makeToast(
        "Sender Id should be less than or equal to 15 characters.",
        {
          variant: "error",
        }
      );
      return;
    }
    setOpen(false);
    const editedObj = { ...props.row };
    editedObj.template_text = templatetext;
    editedObj.template_name = name;
    editedObj.sender_id = senderId;
    if(isMMS) {
      editedObj.template_subject = subject;
      editedObj.template_file = file;
    }
    editedObj.isMMS = isMMS;
    props.onEditTemplateSubmit(editedObj);
    setTemplatetext("");
    setName("");
    setSenderId("");
    setUrl("");
  };

  return (
    <div>
      <ToolTip title="Edit Template">
        <img
          src={edit}
          alt="edit"
          onClick={() => {
            logger.log("props.row", props.row);
            setTemplatetext(props.row.template_text);
            setName(props.row.template_name);
            setSenderId(props.row.sender_id);
            setIsMMS(props.row.is_mms);
            setSubject(props.row.subject)
            // setFile(props.row.file)
            setUrl(props.row.file)
            handleDialogOpen();
          }}
        />
      </ToolTip>
      <ToolTip title="Delete Template">
        <img
          src={certificate}
          alt="certificate"
          onClick={() => {
            const result = window.confirm(
              "Are you sure you want to delete this SMS template? This action is irreversible."
            );
            if (!result) {
              return;
            }
            props.onDeleteTemplateSubmit(props.row);
          }}
        />
      </ToolTip>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="modal-main">
          <DialogTitle id="form-dialog-title">
            <div className="modal-title h4">Edit Template</div>
            <button type="button" className="close" onClick={handleDialogClose}>
              <span aria-hidden="true">x</span>
            </button>
          </DialogTitle>
          <DialogContent id="form-dialog_body">
            <form>
              <label>Type</label>
              <div className="form-groups">
                {isMMS ? 'MMS' : 'SMS'}
              </div>
              <label>Name</label>
              <div className="form-groups">
                <input
                  type="text"
                  id="name"
                  value={name}
                  className="form-controls"
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </div>

              <label>Sender Id</label>
              <div className="form-groups">
                <input
                  type="text"
                  id="senderId"
                  value={senderId}
                  className="form-controls"
                  onChange={(event) => {
                    setSenderId(event.target.value);
                  }}
                />
              </div>
              {
                isMMS ? <>
                <label>Subject</label>
                <div className="form-groups">
                    <input
                      type="text"
                      id="formSubject"
                      value={subject}
                      className="form-controls"
                      onChange={(event) => {
                        setSubject(event.target.value);
                      }}
                    />
                  </div>
                  <label>File*</label>
                  <div className="form-groups">
                    <input
                      id="file"
                      onChange={(event) => {
                        setFileLocal(event.target.files);
                      }}
                      type="file"
                      className="form-controls"
                      accept=".jpg, .jpeg, .png, .gif"
                    />
                    {url && <img src={url} width={200} className="mt-3" />}
                  </div>
                  <Typography style={{ color: "red" }}>
                    *For optimal MMS image quality, utilize JPG format within 500 KB in size. Adjust your image to 640 pixels x 1,138 pixels with a 9:16 aspect ratio for ideal display. Alternatively, consider using 1080 px by 1920 px for your standard image files.
                  </Typography> 

                </> : <></>
              }
              Unsubscribe link: {Constants.unsubscribe_link_show}
              <label>SMS Template</label>
              <div className="form-groups">
                <textarea
                  rows="4"
                  id="formSmsTemplate"
                  className="form-controls"
                  value={templatetext}
                  onChange={(event) => {
                    setTemplatetext(event.target.value);
                  }}
                  inputProps={{
                    maxLength: CHARACTER_LIMIT,
                  }}
                  helperText={`${templatetext.length}/${CHARACTER_LIMIT}`}
                />
              </div>
              <div className="text-limit">
                {templatetext.length}/{CHARACTER_LIMIT}
              </div>
            </form>
          </DialogContent>

          <DialogActions id="dialog-footer">
            <button
              type="button"
              onClick={handleDialogClose}
              className="c-button c-button_secondary"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleDialogSubmit}
              className="c-button c-button_primary"
            >
              Save
            </button>
          </DialogActions>
          {/* Dialog Action Buttons End */}
        </div>
      </Dialog>

      {/* Dialog End */}
    </div>
  );
}
