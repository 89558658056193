const environment = {
  API_SCHEME: "http://",
  API_URL: "localhost:8000",
  API_CONTEXT: "/v1",
  STRIPE_KEY: "pk_test_M1tPrMD9I0wBXSgWCJOQNAZB",
  STRIPE_SUCCESSURL: "http://localhost:3000/dashboard",
  TWO_WAY_SMS_ENDPOINT: "https://localhost:8000/v1/webhook/sms/incoming",
  UNSUBSCRIBE_UI:"http://localhost:3000/u/{c_id}/{ac_contact_id}"
};

// const environment = {
//   API_SCHEME: "https://",
//   API_URL: "api.vybrnt.com",
//   API_CONTEXT: "/SLInterActiveCampaignMiddleware/api/v1",
//   STRIPE_KEY: "pk_live_fty7XSSQ285kCTe1qbqfdHvi",
//   STRIPE_SUCCESSURL: "https://sms-ui.vybrnt.com/VybrntSMSUI/ui/dashboard",
//   TWO_WAY_SMS_ENDPOINT: "https://api.vybrnt.com/SLInterActiveCampaignMiddleware/api/v1/twowayincomingsms",
//   UNSUBSCRIBE_UI: "https://sms-ui.vybrnt.com/ui/unsubscribe",
// };

export default environment