import React from "react";
//import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import Header from "./../../assets/components/header";
import Icon from "./../../assets/components/icon";
import Constants from "./../../Utils/Constants";
import Induction from "./../../Common/Induction";
import store from "./../../Common/storage.js";
import toastMaker from "./../../Common/toastmaker.js";
import FeedbackButton from "./FeedbackButton";
import StrategyPortalButton from "./StrategyPortalButton";
import QuickStartVideoDialog from "./QuickStartVideoDialog";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import HelpIcon from '@material-ui/icons/Help';
import "./index.css";
import axios from "axios";
import { getHeaders, setUserSession } from "./../../Utils/Common";
import { useClipboard } from "use-clipboard-copy";
import FileCopyIcon from '@material-ui/icons/FileCopy';

function MediaCard(props) {

  const [alias, setAlias] = React.useState(store.get('user').configs[0].alias)
  const [host, setHost] = React.useState(store.get('user').configs[0].host)
  const [key, setKey] = React.useState(store.get('user').configs[0].api_key)

  const submitSetup = () => {
    console.log('alias', alias, 'host', host, 'key', key)
    if(alias == '' || host == '' || key == '') {
      toastMaker.makeToast("All fields are mandatory", {
            variant: "error",
      });
    } else {
      axios.post(Constants.checkAcCredsApiPath, {
        host: host,
        key: key
      }, getHeaders())
            .then(response => {
              axios.put(Constants.getApiPathWithConfigId(Constants.configurationsApiPath), {
                alias: alias,
                host: host,
                api_key: key
              }, getHeaders())
                    .then(response => {
                      axios.get(Constants.userApiPath, getHeaders())
                                .then(r => {
                                  let data = r.data.data
                                  setUserSession(store.get('token'), store.get('refresh_token'), store.get('expires_in'), data, props.history);
                                  toastMaker.makeToast("Welcome to VYBRNT SMS!", {
                                    variant: "success",
                                  })
                                  Constants.navigate(Constants.dashboard)

                                })
                                .catch(e => console.log(e, 'e'))
                    }).catch(e => {})
                    .catch(
                      error => toastMaker.makeToast("Something went wrong, please try again", {
                                            variant: "error",
                                      })
                      )
            })
            .catch(
              error => toastMaker.makeToast("Wrong details, Please check API URL and API KEY", {
                                    variant: "error",
                              })
              )
    }
  }

  const clipboard = useClipboard({
    onSuccess() {
      toastMaker.makeToast(
        `Great! Now you can paste API Key in your ActiveCampaign app setup.`,
        {
          variant: "success",
        }
      );
      //logger.log("Text was copied successfully!");
    },
    onError() {
      toastMaker.makeToast("'Failed to copy API Key!'", {
        variant: "error",
      });
      //logger.log("Failed to copy text!");
    },
  });

  const content = (
    <ul style={{ listStyleType: "circle" }}>
      {/* <li>
        <p>
          Connect your Account{" "}
          <a
            style={{ fontWeight: "bold" }}
            onClick={(event) => {
              event.preventDefault();
              props.history.push(Constants.settings);
            }}
            href=""
          >
            CLICK HERE
          </a>
        </p>
      </li>
      <li>
        <p>
          Purchase Credits{" "}
          <a
            style={{ fontWeight: "bold" }}
            onClick={(event) => {
              event.preventDefault();
              Constants.openCredits();
            }}
            href=""
          >
            CLICK HERE
          </a>
        </p>
      </li>
      <li>
        <p>
          To Create an SMS Campaign{" "}
          <a
            style={{ fontWeight: "bold" }}
            onClick={(event) => {
              event.preventDefault();
              props.history.push(Constants.smsautomation);
            }}
            href=""
          >
            CLICK HERE
          </a>
        </p>
      </li>
      <li>
        <p>
          To Create an SMS Template{" "}
          <a
            style={{ fontWeight: "bold" }}
            onClick={(event) => {
              event.preventDefault();
              props.history.push(Constants.smstable);
            }}
            href=""
          >
            CLICK HERE
          </a>
        </p>
      </li>
      <li>
        <p>
          For a quick start video <QuickStartVideoDialog />
        </p>
      </li> */}

      {/* <li>
        <p>
          Get started by <a href="https://vybrnt.com/activecampaign-blog/" target="_blank" style={{ fontWeight: "bold" }}> clicking here. </a>
        </p>
      </li> */}

      <li>
        {/* <p>
          Access VYBRNT SMS training materials and blogs by <a href="https://vybrnt.com/activecampaign-blog/" target="_blank" style={{ fontWeight: "bold" }}> clicking here. </a>
        </p> */}
        <p>
          Watch the "GET STARTED" video: <a href="https://youtu.be/tOnuXFEUnqo" target="_blank" style={{ fontWeight: "bold" }}>https://youtu.be/tOnuXFEUnqo</a>
        </p>
      </li>

      <li>
        <p>
        Access additional VYBRNT SMS training materials and blogs: <a href="https://vybrnt.com/activecampaign-blog/" target="_blank" style={{ fontWeight: "bold" }}> https://vybrnt.com/activecampaign-blog/ </a>
        </p>
      </li>

      <li>
        <p>
          Book a training call: <a href="https://calendly.com/tim-vybrnt/30min" target="_blank" style={{ fontWeight: "bold" }}> https://calendly.com/tim-vybrnt/30min </a>
        </p>
      </li>

    </ul>
  );

  // const showSuccessMessage = new URLSearchParams(props.location.search).get("isSuccessMessage") === 'true';
  // if(showSuccessMessage) {
  //   toastMaker.makeToast("Payment Successful!", {
  //     variant: "success",
  //   });
  //   props.history.push(Constants.dashboard);
  // }
  // const showErrorMessage = new URLSearchParams(props.location.search).get("isErrorMessage") === 'true';
  // if(showErrorMessage) {
  //   toastMaker.makeToast("Payment Failed!", {
  //     variant: "error",
  //   });
  //   props.history.push(Constants.dashboard);
  // }

  if(store.get('user').configs[0].host == '' || store.get('user').configs[0].api_key == '') {
    return (
      <div>
        <Header name={"Setup"} />  
        <Induction name={props.name} content={<>
          <p>How to?</p>
          <ol>
            <li>Alias is the name of your business or ActiveCampaign account. This can be anything you want.</li>
            <li>Login to your ActiveCampaign account.</li>
            <li>Click on Settings (gear icon) in the menu.</li>
            <li>Navigate to Developer.</li>
            <li>Copy the URL and paste it into the API URL below.</li>
            <li>Copy the Key and paste it into the API Key below.</li>
          </ol> 
          <p> Tip </p>
          <ul>
            <li>In case you do not have an {' '}
              <a href="https://www.activecampaign.com/?_r=WEH67FJT" target="_blank" > 
                ActiveCampaign account, click here to create a new one.
              </a>
              </li>
              {/* <li style={{cursor:"pointer"}}
                  className=""
                  onClick={() => {
                    if (clipboard.isSupported()) {
                      clipboard.copy(store.get('user').configs[0].cxapptoken.token);
                    } else {
                      toastMaker.makeToast(
                        "Copy to clipboard not supported by browser.",
                        {
                          variant: "error",
                        }
                      );
                    }
                  }}
                >
                  Your CX App API Key is {store.get('user').configs[0].cxapptoken.token} {' '}
                  <FileCopyIcon />
                  
              </li> */}
          </ul>
            
          </>
        } />
        <div>
          <Paper className="account-details"
            elevation={3}
            style={{
              margin: "0 auto",
              maxWidth: "700px",
              padding: "10px",
              marginBottom: "100px",
            }}
          >
            <div>
              <Typography className={''}>
                Details
              </Typography>

              <form>
                <div className="form-groups">
                  <label>Alias 
                    <div class="tooltip ml-2">
                      <HelpIcon />
                      <span class="tooltiptext">
                          The name of your business or ActiveCampaign account. This can be anything you want.
                      </span>
                    </div>
                  </label>
                  <input
                    id="alias"
                    value={alias}
                    onChange={(event) => {
                      setAlias(event.target.value);
                    }}
                    type="text"
                    className="form-controls"
                    placeholder="Alias"
                  />
                </div>

                <div className="form-groups">
                  <label>API URL</label>  
                  <input
                    id="host"
                    value={host}
                    onChange={(event) => {
                      setHost(event.target.value);
                    }}
                    type="text"
                    className="form-controls"
                    placeholder="API URL"
                  />
                </div>

                <div className="form-groups">
                  <label>API Key</label>  
                  <input
                    id="key"
                    value={key}
                    onChange={(event) => {
                      setKey(event.target.value);
                    }}
                    type="text"
                    className="form-controls"
                    placeholder="API Key"
                  />
                </div>

                <div className="form-groups mt-5">
                  <span
                  className="c-button text-uppercase"
                  style={{ marginRight: "10px" }}
                  onClick={submitSetup}
                >
                  Submit
                </span>
              </div>

              </form>
            </div>
          </Paper>

        </div>
      </div>
    )
  }

  return (
    <div>
      <Header name={"Dashboard"} />      
      <Induction name={props.name} content={content} />
      <Grid container spacing={3} className="grid-bottom-spacing">
        <Grid item xs={12} sm={6} md={3} lg={3} className="zoom">
          <div className="sms-auto_tab">
            <h2>SMS</h2>
            <h2>Templates</h2>
            <p>
              {/* Easily create SMS message copy, label them, and organise them for
              all your campaigns and nurture sequences (ActiveCampaign
              Automations). */}
              Easily Create SMS Message Copy, Label And Organize Them 
              For All Your Campaigns And Nurture Sequences.
            </p>
            <Icon
              className="zoombutton"
              name="send-mail"
              onClick={() => {
                Constants.navigate(Constants.smstable);
              }}
            />
            <div className="clear"></div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}  className="zoom">
          <div className="sms-auto_tab">
            <h2>sms</h2>
            <h2>Campaigns</h2>
            <p>
              {/* Easily create an SMS campaign. Send a promotion out to a Synched
              ActiveCampaign list. */}
              Easily Send An SMS Or MMS Campaign To One Of 
              Your ActiveCampaign Lists And/Or Segments.
            </p>
            <Icon
              className="zoombutton"
              name="send-mail"
              onClick={() => {
                Constants.navigate(Constants.smsautomation);
              }}
            />
            <div className="clear"></div>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3}  className="zoom">
          <div className="sms-auto_tab">
            <h2>TRAINING</h2>
            <h2>CALL</h2>
            <p>
              {/* Easily create SMS message copy, label them, and organise them for
              all your campaigns and nurture sequences (ActiveCampaign
              Automations). */}
              Book A Complimentary 1:1 Call With A VYBRNT Representative 
              For SMS Or ActiveCampaign Optimization Support.
              
            </p>
            <Icon
              className="zoombutton"
              name="send-mail"
              onClick={() => {
                window.open("tel:+61450611664");
              }}
            />
            <div className="clear"></div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}  className="zoom">
          <div className="sms-auto_tab">
            <h2>SHARE</h2>
            <h2>ACTIVECAMPAIGN</h2>
            <p>
            Want To Share ActiveCampaign With A Friend? Here's A Link To A Free Trial.
              
            </p>
            <Icon
              className="zoombutton"
              name="send-mail"
              onClick={() => {
                window.open("mailto:?subject=Here's a Free Trial to ActiveCampaign.&body=Hey,%0D%0A %0D%0A Here's a link to a free trial of ActiveCampaign: https://www.activecampaign.com/?_r=WEH67FJT, %0D%0A%0D%0AUnlock the potential of your sales and marketing strategies with ActiveCampaign, a robust CRM system designed to streamline your processes and drive growth.%0D%0A%0D%0A https://www.activecampaign.com/?_r=WEH67FJT %0D%0A %0D%0ABest Regards,")
              }}
            />
            <div className="clear"></div>
          </div>
        </Grid>
      </Grid>
      <FeedbackButton />
      <StrategyPortalButton />
      <Icon
        alt="logo"
        name={"vybrnt-footer-logo"}
        className="footer-logo"
      />
    </div>
  );
}

// export default withRouter(MediaCard);
export default MediaCard;
