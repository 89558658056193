const storeKey = "store";
const storageMedia = localStorage;

const get = (key) => {
  const store = getStore();
  return store[key];
};

const set = (key, value) => {
  const store = getStore();
  store[key] = value;
  setStore(store);
};

const removeItem = (key) => {
  const store = getStore();
  delete store[key];
  setStore(store);
};

const getStore = () => {
  return JSON.parse(storageMedia.getItem(storeKey)) || {};
};

const setStore = (store) => {
  const storeStr = JSON.stringify(store);
  storageMedia.setItem(storeKey, storeStr);
};

const clear = () => {
  setStore({});
};

export default {
  get,
  set,
  removeItem,
  getStore,
  clear,
};
