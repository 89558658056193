import React, {useEffect} from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import { getToken, getUser, saveUser } from "./../../../Utils/Common";
import Constants from "./../../../Utils/Constants";
import toastMaker from "./../../../Common/toastmaker.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledExpansionPanels() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [generalSettings, setGeneralSettings] = React.useState([]);

  const settings = [
     {
      name: 'campaignTag',
      value: 1,
      field: 'general_setting_campaign_tag',
      type: 'checkbox',
      lbl: 'Create tag of Campaign Name'
    },
    {
      name: 'sms_2way',
      value: 'STOP',
      field: 'general_setting_unsubscibe_words',
      type: 'text',
      lbl: 'Words to unsubscribe'
    }
  ]
  

  useEffect(() => {
    load()
  }, [])

  const load = () => {
    axios.get(Constants.getApiPathWithConfigId(Constants.getSettingsApiPath) + ``, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
                      .then(response => {
                        if(response.data.meta.count) {
                          let temp = {}
                          response.data.data.map(e => {
                            settings.map(s => {
                                if(s.field == e.key) {
                                  let t = {
                                    name: s.name,
                                    value: e.value,
                                    field: s.field,
                                    type: s.type,
                                    lbl: s.lbl,
                                    id: e.id
                                  }
                                  temp[s.field] = t
                                }
                            })
                          })
                          temp = Object.values(temp)
                          temp = temp.length ? temp : settings
                          setGeneralSettings(temp)
                        } else {
                          setGeneralSettings(settings)
                        }
                      }).catch(error => console.error(error))
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

    const handleSave = () => {
      generalSettings.map(setting => {
        let data = {
          key: setting.field,
          value: setting.value
        }
        
        setting?.id ? 
        axios.put(Constants.getApiPathWithConfigId(Constants.getSettingsApiPath) + `/${setting.id}`, data,{
          headers: { Authorization: `Bearer ${getToken()}` },
        }).then(response => {
          toastMaker.makeToast("Settings updated successfully", {
            variant: "success",
          })
          load()
          // console.log(response)
        }).catch(error => console.error(error))
        :
        axios.post(Constants.getApiPathWithConfigId(Constants.getSettingsApiPath) + ``, data, {
          headers: { Authorization: `Bearer ${getToken()}` },
        }).then(response => {
          toastMaker.makeToast("Settings updated successfully", {
            variant: "success",
          })
          load()
          // console.log(response)        
        }).catch(error => console.error(error))
      })
    }
  



  return (
    <ExpansionPanel
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography className={classes.heading}>
          <span className="mb-10"><b>General Settings</b></span>
        </Typography>
        <Typography className={classes.secondaryHeading}>
          Application settings
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: "block" }}>
        <ul style={{ marginLeft: "40px" }}>
          {
            generalSettings?.map((setting, i) => (<li>
              <Typography className={classes.heading}>
                {setting.lbl}: { 
                                  <input 
                                    type={setting.type} 
                                    value={setting.value} 
                                    checked={setting.value == 1 ? true : false}
                                    onChange={e => {
                                      let sets = [...generalSettings]
                                      sets[i].value   = setting.type == 'checkbox' ? e.target.checked ? 1 : 0 : e.target.value
                                      setGeneralSettings(sets)
                                    }}
                                  /> 
                                }
              </Typography>
              
            </li>))
          }
          
        </ul>
        <button
          type="button"
          onClick={handleSave}
          className="c-button c-button_primary"
        >
          Save
        </button>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
