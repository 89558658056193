import React from "react";
import logger from "./../../logger";
import axios from "axios";
import TagMenu from "./TagMenu";
import Chip from "@material-ui/core/Chip";
import { getHeaders } from "./../../Utils/Common";
import Constants from "./../../Utils/Constants";

/*
import TagSection from "Common/TagSection";
<TagSection
                baseUrl={apiPath + "/api/moviesorter"}
                tags={values.tags}
                setTags={(tags) => {
                  addValues({
                    tags,
                  });
                }}
              />
*/

export default function TagSection(props) {
  logger.log("TagSection", props);
  const { templateId } = props;

  const [tags, setTags] = React.useState(props.row.tags);

  return (
    <div style={{}}>
      <TagMenu
        selected={tags}
        addTagToMovie={(tag) => {
          let t = tags.split(',')
          t.push(tag)
          t = t.filter(e => e != '').join(',')
          axios.put(Constants.getApiPathWithConfigId(Constants.updateTemplateApiPath) + '/' + templateId, { tags: t }, getHeaders())
               .then((response) => {
                  logger.log("add Tags response", response.data);
                  setTags(response.data.data.tags);
              }).catch(e =>console.error(e));
        }}
        addTagToTagSet={() => {
          // logger.log("adding to tagset");
        }}
        tagSet={[]}
      />
      <div style={{ textAlign: "center" }}>
        {tags &&
          tags.split(',').map((value) => (
            <Chip
              key={value}
              label={value}
              style={{ margin: "2px" }}
              onDelete={(e) => {
                let t = tags.split(',').filter(item => item !== value).join(',')

                axios.put(Constants.getApiPathWithConfigId(Constants.updateTemplateApiPath) + '/' + templateId, { tags: t }, getHeaders())
                      .then((response) => {
                          logger.log("add Tags response", response.data);
                          setTags(response.data.data.tags);
                      }).catch(e =>console.error(e))
              }}
            />
          ))}
      </div>
    </div>
  );
}
