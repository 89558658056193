import React from "react";
import logger from "./../../../logger";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";

import toastMaker from "./../../../Common/toastmaker";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#0D2645",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  previewcard: {
    maxWidth: 345,
    margin: "15px",
    display: "grid",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const {
    bulkSMSConfig,
    contactsWithPhone,
    selectedTwoWayNumber,
    useTwoWayNumber,
    isMMS,
  } = props;

  const handleClickOpen = () => {
    if (!contactsWithPhone || contactsWithPhone.length === 0) {
      toastMaker.makeToast(
        "Please ensure that atleast one contact exists to which SMS can be sent!",
        {
          variant: "error",
        }
      );
      return;
    }
    if (contactsWithPhone.length === 0 || contactsWithPhone.length > 10000) {
      toastMaker.makeToast(
        "Total number of contacts with phone numbers in the list should be between 0 and 10,000.",
        {
          variant: "error",
        }
      );
      return;
    }
    if (useTwoWayNumber && selectedTwoWayNumber) {
      if (!bulkSMSConfig.template || bulkSMSConfig.template.length === 0) {
        toastMaker.makeToast("SMS Template is mandatory.", {
          variant: "error",
        });
        return;
      }
    } else {
      if (
        !bulkSMSConfig ||
        !bulkSMSConfig.senderId ||
        bulkSMSConfig.senderId.length === 0 ||
        !bulkSMSConfig.template ||
        bulkSMSConfig.template.length === 0
      ) {
        toastMaker.makeToast("Sender Id and SMS Template are mandatory.", {
          variant: "error",
        });
        return;
      }
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ display: "inline" }}>
      <button
        type="button"
        onClick={handleClickOpen}
        className="c-button c-button_primary"
      >
        Preview
      </button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Campaign SMS Preview
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="left"
          alignItems="center"
        >
          {contactsWithPhone.map((contact) => {
            // logger.log(contact, bulkSMSConfig);
            return (
              <Card className={classes.previewcard + " speech-bubble"}>
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant="body2">
                      <sup>From:</sup>
                      {bulkSMSConfig.senderId}
                    </Typography>
                    <Typography gutterBottom variant="body2">
                      <sup>To:</sup>
                      {contact.phone}({contact.firstName} {contact.lastName})
                    </Typography>
                    <Typography gutterBottom variant="body2">
                      <sup>SMS:</sup>
                    </Typography>
                    {isMMS && bulkSMSConfig.subject &&
                      bulkSMSConfig.subject.length > 0 && (
                        <div className="speech-bubble">
                          {bulkSMSConfig.subject}
                        </div>
                      )}

                    {isMMS && bulkSMSConfig.url &&
                      bulkSMSConfig.url.length > 0 && (
                        <div className="speech-bubble">
                          <img src={bulkSMSConfig.url} width={200} />
                        </div>
                      )}

                    {bulkSMSConfig.template &&
                      bulkSMSConfig.template.length > 0 && (
                        <div className="speech-bubble">
                          {bulkSMSConfig.template
                            .replace("$first_name", contact.firstName)
                            .replace("$last_name", contact.lastName)
                            .replace("$email", contact.email)
                            .replace("%FIRSTNAME%", contact.firstName)
                            .replace("%LASTNAME%", contact.lastName)
                            .replace("%EMAIL%", contact.email)}
                        </div>
                      )}
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })}
        </Box>
      </Dialog>
    </div>
  );
}
