import React from "react";
//import { withRouter } from "react-router-dom";
import Constants from "./../../Utils/Constants";
import vybrntwhite from "./vybrnt-sms-logo.svg";

import "./index.scoped.css";

function HomeLogo(props) {
  return (
    <div
      className="brand-name"
      onClick={() => {
        props.history.push(Constants.home);
      }}
    >
      <img className="white-logo" src={vybrntwhite}></img>
    </div>
  );
}

// export default withRouter(HomeLogo);
export default HomeLogo;
