let environment = require("./../../environments");

if(environment.default) {
    environment = environment.default;
}

const basepath = '';
exports.home = basepath + "/";
exports.login = basepath + "/login";
exports.quickview = basepath + "/quickview/:embed";
exports.forgotpassword = basepath + "/forgotpassword";
exports.resetpassword = basepath + "/resetpassword";
exports.signup = basepath + "/signup";
exports.unsubscribe = basepath + "/u/:id/:ac_id";
exports.unsubscribeshort = basepath + "/u/:id/";
exports.dashboard = basepath + "/dashboard";
exports.smstable = this.dashboard + "/smstable";
exports.history = this.dashboard + "/history";
exports.campaignhistory = this.dashboard + "/campaignhistory";
exports.conversations = this.dashboard + "/conversations";
exports.smsautomation = this.dashboard + "/smsautomation";
exports.settings = this.dashboard + "/settings";

//http://13.211.219.124/SLInterActiveCampaignMiddleware/api/v1/history
// const scheme = "http://";
let scheme = environment.API_SCHEME;
let hostname = environment.API_URL;
let apibasepath = environment.API_CONTEXT;

// logger.log(process.env)
// logger.log(scheme + hostname + apibasepath)
exports.sendSMSApiPath = scheme + hostname + apibasepath + "/sendSMS";
exports.templatesApiPath = scheme + hostname + apibasepath + "/{c_id}/templates";
exports.templateidsApiPath = scheme + hostname + apibasepath + "/templateids";
exports.templateidApiPath = scheme + hostname + apibasepath + "/templateid";
exports.historyApiPath = scheme + hostname + apibasepath + "/{c_id}/automations";
exports.campaignhistoryApiPath = scheme + hostname + apibasepath + "/{c_id}/campaign";
exports.campaignhistorydetailApiPath = scheme + hostname + apibasepath + "/aclist/contacts/sendSMS/campaign";
exports.campaignsmssendlog = scheme + hostname + apibasepath + "/aclist/contacts/sendSMS/campaignsmssendlog";
exports.sendtwowaysmsreplyApiPath = scheme + hostname + apibasepath + "/{c_id}/virtual-number-messages";
exports.conversationsApiPath = scheme + hostname + apibasepath + "/{c_id}/virtual-number-messages/{v_number}/number";

exports.loginApiPath = scheme + hostname + apibasepath + "/oauth/token";
exports.forgotpasswordApiPath = scheme + hostname + apibasepath + "/users/otp";
exports.resetpasswordApiPath = scheme + hostname + apibasepath + "/users/password";
exports.signupApiPath = scheme + hostname + apibasepath + "/users";
exports.signupsecondaryApiPath = scheme + hostname + apibasepath + "/{c_id}/users";
exports.acconfigApiPath = scheme + hostname + apibasepath + "/acconfig";
exports.aclistsApiPath = scheme + hostname + apibasepath + "/{c_id}/campaign/lists";
exports.acsegmentsApiPath = scheme + hostname + apibasepath + "/{c_id}/campaign/segments";

exports.aclistContactsApiPath = scheme + hostname + apibasepath + "/{c_id}/campaign/contacts/{l_id}/{s_id}?per_page=100";
exports.aclistContactsSendSMSApiPath = scheme + hostname + apibasepath + "/{c_id}/campaign";

exports.createCheckoutSessionApiPath = scheme + hostname + apibasepath + "/checkout/sessions";
exports.getCreditsApiPath = scheme + hostname + apibasepath + "/credits";
exports.getAllPricesApiPath = scheme + hostname + apibasepath + "/prices";

exports.addTagApiPath = scheme + hostname + apibasepath + "/addTag";
exports.deleteTagApiPath = scheme + hostname + apibasepath + "/deleteTag";
exports.getTagsApiPath = scheme + hostname + apibasepath + "/getTags";

exports.unsubscribeApiPath = scheme + hostname + apibasepath + "/{c_id}/unsubscribe";

exports.getAllCXAppTokensApiPath = scheme + hostname + apibasepath + "/cxapptokens";
exports.addCXAppTokensApiPath = scheme + hostname + apibasepath + "/cxapptoken";
exports.deleteCXAppTokensApiPath = scheme + hostname + apibasepath + "/cxapptoken";

exports.sendTestSMSApiPath = scheme + hostname + apibasepath + "/{c_id}/campaign/sendsms";
exports.sendTestMMSApiPath = scheme + hostname + apibasepath + "/{c_id}/campaign/sendmms";

exports.userApiPath = scheme + hostname + apibasepath + "/current-user";

exports.useracaccountApiPath = scheme + hostname + apibasepath + "/users/account/ac/";
exports.useraccontactApiPath = scheme + hostname + apibasepath + "/users/ac/";

exports.checkAcCredsApiPath = scheme + hostname + apibasepath + "/ac/check_connection"
exports.configurationsApiPath = scheme + hostname + apibasepath + "/configurations/{c_id}"
exports.configurationsListApiPath = scheme + hostname + apibasepath + "/public/configurations"
exports.updateTemplateApiPath = scheme + hostname + apibasepath +  "/{c_id}/templates"
exports.getACContactDetailsById = scheme + hostname + apibasepath +  '/{c_id}/users/ac/{ac_id}'
exports.getACContactDetailsByMobile = scheme + hostname + apibasepath +  '/{c_id}/users/ac/{number}/number'
exports.createFileApiPath = scheme + hostname + apibasepath +  "/{c_id}/file"

exports.markallcoversationsreadApiPath = scheme + hostname + apibasepath +  '/{c_id}/virtual-number-messages/mark-all-read/{virtual_number}'
exports.markcoversationsreadApiPath = scheme + hostname + apibasepath +  '/{c_id}/virtual-number-messages/mark-read/{virtual_number}/{number}'
exports.virtualnumbermsgs = scheme + hostname + apibasepath + "/{c_id}/public-virtual-number-messages";

exports.getSettingsApiPath = scheme + hostname + apibasepath +  '/{c_id}/settings'
exports.getACCustomFieldsApiPath = scheme + hostname + apibasepath +  '/{c_id}/settings/custom-fields/ac'
exports.postACCustomFieldsApiPath = scheme + hostname + apibasepath +  '/{c_id}/settings/conversations'

///aclist/contacts/sendSMS/campaign
