import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LoadingProgress from "./../../../Common/LoadingProgress";
import { getToken, getUser, saveUser, getHeaders } from "./../../../Utils/Common";
import { Input } from "@material-ui/core";

import ToolTip from "@material-ui/core/ToolTip";
import edit from "./edit.svg";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import Constants from "./../../../Utils/Constants/index"
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 450,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ControlledExpansionPanels() {
  
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen]           = React.useState(false)
  const [users, setUsers] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [modeCreate, setModeCreate] = React.useState(false);
  
  const handleDialogClose = () => setOpen(false)
  const handleDialogOpen  = () => setOpen(true)
  
  const handleDialogSubmit = () => {
    if(modeCreate) {
      axios.post(Constants.getApiPathWithConfigId(Constants.signupsecondaryApiPath), 
        user,
        getHeaders())
        .then(response => {
          handleDialogClose()
          load()
        })
        .catch(error => console.error(error))
    } else {
      axios.put(Constants.signupApiPath + `/${user.id}`, 
        user,
        getHeaders())
        .then(response => {
          handleDialogClose()
          load()
        })
        .catch(error => console.error(error))
    }
      
  }
    

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const load = () => {
    axios.get(Constants.getApiPathWithConfigId(Constants.configurationsApiPath), getHeaders())
         .then(response => {
            // console.info('re', response.data.data.users)
            setUsers(response.data.data.users)
          })
         .catch(error => console.error(error))

  }

  const setCurrentUser = i => {
    setUser(users[i])
    handleDialogOpen();
  }

  React.useState(() => {
    load()
  }, [])

  if (isLoading) {
    return <LoadingProgress />;
  }


  return (
    <ExpansionPanel
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography className={classes.heading}>
          <span className="mb-10"><b>Users</b></span>
        </Typography>
        <Typography className={classes.secondaryHeading}>
          Configure system user
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: "block" }}>
        {users &&
          users.map((user, i) => {
            return (
              <tr>
                <td style={{ width: "100%" }}>
                  <div style={{ padding: "10px" }}>
                    <Typography style={{ fontWeight: "bold", wordBreak: "break-all" }}>
                      {user.name}
                    </Typography>
                    <Typography >
                      Email id - {user.email}
                    </Typography>
                    <Typography >
                    Mobile number - {user.mobile}
                    </Typography>
                  </div>
                </td>
                <td style={{ width: "100%" }}>
                  <div style={{ padding: "10px" }}>
                    <div style={{ display: "flex" }}>
                      <ToolTip title="Edit Config">
                        <img
                          src={edit}
                          alt="edit"
                          onClick={() => {
                            setModeCreate(false)
                            setUser(user)
                            handleDialogOpen();
                          }}
                        />
                      </ToolTip>
      {/* <ToolTip title="Delete user">
        <img
          src={edit}
          alt="Delete"
          onClick={() => {
            const result = window.confirm(
              "Are you sure you want to delete this user? This action is irreversible."
            );
            if (!result) {
              return
            }
            alert('deleted')
          }}
        />
      </ToolTip> */}

      
    </div>
                  </div>
                </td>
              </tr>
            );
          })}

      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="modal-main">
          <DialogTitle id="form-dialog-title">
            <div className="modal-title h4">
              { modeCreate ? 'Create ' : 'Edit ' } User
            </div>
            <button
              type="button"
              className="close"
              onClick={handleDialogClose}
              style={{ marginLeft: "0px" }}
            >
              <span aria-hidden="true">x</span>
            </button>
          </DialogTitle>
          <DialogContent id="form-dialog_body">
            <form>
              
              <label>Name</label>
              <div className="form-groups">
                <input
                  type="text"
                  id="formName"
                  className="form-controls"
                  value={user.name}
                  onChange={(event) => {
                    setUser({...user, 'name': event.target.value});
                  }}
                />
              </div>  

              <label>Email id</label>
              <div className="form-groups">
                <input
                  type="text"
                  id="formName"
                  className="form-controls"
                  value={user.email}
                  onChange={(event) => {
                    setUser({...user, 'email': event.target.value});
                  }}
                />
              </div>

              <label>Mobile number</label>
              <div className="form-groups">
                <input
                  type="text"
                  id="formName"
                  className="form-controls"
                  value={user.mobile}
                  onChange={(event) => {
                    setUser({...user, 'mobile': event.target.value});
                  }}
                />
              </div>

              {
                modeCreate && <>
                <label>Password</label>
                <div className="form-groups">
                  <input
                    type="password"
                    id="formName"
                    className="form-controls"
                    value={user.password}
                    onChange={(event) => {
                      setUser({...user, 'password': event.target.value});
                    }}
                  />
                </div>
              </>
              }

            </form>
          </DialogContent>
          <DialogActions id="dialog-footer">
            <button
              type="button"
              onClick={() => {
                handleDialogClose()
                load()
              }}
              className="c-button c-button_primary"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleDialogSubmit}
              className="c-button c-button_secondary"
            >
              Save
            </button>
          </DialogActions>
        </div>
      </Dialog>
      {users.length < 3 &&
          <button
              type="button"
              onClick={() => {
                setModeCreate(true)
                setUser({})
                handleDialogOpen();
              }}
              className="c-button c-button_primary"
            >
              Create
            </button>
        }
      </ExpansionPanelDetails>
    </ExpansionPanel>
    
  );
}
