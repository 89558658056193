import React, {useState, useEffect} from 'react';

const Icon = ((props) => {
  const [image, setImg] = useState(false);

  useEffect(() => {
    import(`./../images/${props.name}.svg`).then(image => {
      setImg(image.default)
    })
  }, [])
  
  return image && <img
    {...props}
    src={image}
    alt={props.name}
  />
});

export default Icon;
