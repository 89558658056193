import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

import SendTestDialog from "./SendTestDialog";
import ImportFromTemplates from "./ImportFromTemplates";
import HelpIcon from '@material-ui/icons/Help';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Typography from "@material-ui/core/Typography";
import { useClipboard } from "use-clipboard-copy";
import toastMaker from "./../../../Common/toastmaker.js";
import Constants from "./../../../Utils/Constants";
import MobilePreview from "../MobilePreview/index.js";

const CHARACTER_LIMIT = 160;

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

export default function BulkSMSForm(props) {

  const classes = useStyles();
  
  const [templatetext, setTemplatetext] = React.useState(
    "Type your SMS message here.\nFor marketing message compliance, you can use the optout hyperlink at the end of this message as it is. (http://vc1.me/c/{code})"
  );

  const [senderId, setSenderId] = React.useState(null);
  const [subject, setSubject]   = React.useState(null);
  const [file, setFile]         = React.useState([]);
  const [url, setUrl]           = React.useState(null);

  const setBulkConfig = template => {
    // console.log(template, 'setbc')
    const bulkSMSConfig = {
      senderId: template.sender_id,
      template: template.template_text,
      subject: template.subject,
      url: template.file,
      file: null
    };
    setSenderId(template.sender_id)
    setTemplatetext(template.template_text)
    setSubject(template.subject)
    setUrl(template.file)
    props.setbulkSMSConfig(bulkSMSConfig);
  }

  const setSenderIdOnParent = (value) => { 
    setSenderId(value);
    const bulkSMSConfig = {
      senderId: value,
      template: templatetext,
      subject: subject,
      file: file,
      url: url
    };
    props.setbulkSMSConfig(bulkSMSConfig);
  };

  const setTemplateOnParent = (value) => {
    setTemplatetext(value);
    const bulkSMSConfig = {
      senderId: senderId,
      template: value,
      subject: subject,
      file: file,
      url: url
    };
    props.setbulkSMSConfig(bulkSMSConfig);
  };

  const setSubjectOnParent = (value) => {
    setSubject(value);
    const bulkSMSConfig = {
      senderId: senderId,
      template: templatetext,
      subject: value,
      file: file,
      url: url
    };
    props.setbulkSMSConfig(bulkSMSConfig);
  };

  const setFileOnParent = (value) => {
    setFile(value);
    const bulkSMSConfig = {
      senderId: senderId,
      template: templatetext,
      subject: subject,
      file: value,
      url: url
    };
    props.setbulkSMSConfig(bulkSMSConfig);
  };

  const setUrlOnParent = (value) => {
    setUrl(value);
    const bulkSMSConfig = {
      senderId: senderId,
      template: templatetext,
      subject: subject,
      file: file,
      url: value
    };
    props.setbulkSMSConfig(bulkSMSConfig);
  };

  const clipboard = useClipboard({
    onSuccess() { 
      toastMaker.makeToast(
        `Great! Now you can paste unsubscribe link in SMS Content.`,
        {
          variant: "success",
        }
      );
      //logger.log("Text was copied successfully!");
    },
    onError() { 
      toastMaker.makeToast("'Failed to copy API Key!'", {
        variant: "error",
      });
      //logger.log("Failed to copy text!");
    },
  });

  useEffect(() => { 
    if(file.length) {
      setUrlOnParent(URL.createObjectURL(file[0]));
    }
  }, [file])

  return (
    <div>
      <form>
        {!props.useTwoWayNumber && (
          <>
            <label>Sender Id*</label>
            <div className="form-groups">
              <input
                id="senderId"
                value={senderId}
                onChange={(event) => {
                  setSenderIdOnParent(event.target.value);
                }}
                type="text"
                className="form-controls"
              />
            </div>
          </>
        )}
        {props.isMMS && (<>
          <label>Subject*
          <div class="tooltip ml-2">
                      <HelpIcon />
                      <span class="tooltiptext">
                      Similar to an email subject line.
                      </span>
                    </div>
                    </label> 
            <div className="form-groups">
              <input
                id="subject"
                value={subject}
                onChange={(event) => {
                  setSubjectOnParent(event.target.value);
                }}
                type="text"
                className="form-controls"
              />
            </div>
            <label>File*</label>
            <div className="form-groups">
              <input
                id="file"
                onChange={(event) => {
                  setFileOnParent(event.target.files);
                }}
                type="file"
                className="form-controls"
                accept=".jpg, .jpeg, .png, .gif"
              />
              {url && <img src={url} width={200} className="mt-3" />}
            </div>
            <Typography style={{ color: "red" }}>
              *For optimal MMS image quality, utilize JPG format within 500 KB in size. Adjust your image to 640 pixels x 1,138 pixels with a 9:16 aspect ratio for ideal display. Alternatively, consider using 1080 px by 1920 px for your standard image files.
            </Typography> 
        </>)}
        Unsubscribe link: {Constants.unsubscribe_link_show}
        <label>SMS Template*</label>
        <div className="form-groups">
          <textarea
            rows="6"
            id="template"
            className="form-controls"
            value={templatetext}
            onChange={(event) => {
              setTemplateOnParent(event.target.value);
            }}
            inputProps={{
              maxLength: CHARACTER_LIMIT,
            }}
            helperText={`${templatetext.length}/${CHARACTER_LIMIT}`}
            placeholder={
              "Example:\nHi %FIRSTNAME% %LASTNAME%,\nPlease respond to your email on %EMAIL%.\nThanks!"
            }
          />
        </div>
        {!props.isMMS ? <div className="text-limit">{`${
          templatetext.length
        }/${CHARACTER_LIMIT}`}</div> : <></>}
      </form>
      {!props.isMMS ? <Typography style={{ color: "red" }}>
        <span style={{ display: "block" }}>*If your message is above 160 characters, it will charge for two SMS per
        person. </span>
        <span style={{ fontweight: "600" }}>*Please refrain from using emojis in your message.</span>
      </Typography> : <Typography style={{ color: "red" }}>
        *We recommend about 500 characters for an MMS. This will still count as one MMS in total.
      </Typography> 
      }
      <div style={{ margin: "0 auto", maxWidth: "700px" }}>
        <div
          style={{
            paddingTop: "30px",
            paddingBottom: "20px",
            textAlign: "end",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <ImportFromTemplates
            isMMS={props.isMMS}
            onTemplateSelected={async (template) => {
              // console.log(template, 'template')
              // await setSenderIdOnParent(template?.sender_id);
              // await setTemplateOnParent(template?.template_text);
              // // if(props.isMMS) {
              // //   setSubjectOnParent(template?.subject);
              // //   setUrlOnParent(template?.file);
              // // }
              setBulkConfig(template)
            }}
          />
          <SendTestDialog 
            templatetext={templatetext} 
            senderId={senderId} 
            isMMS={props.isMMS} 
            subject={subject}
            file={file}
            url={url}
          />
        </div>
      </div>
    <MobilePreview
        bulkSMSConfig={
          {
            subject,
            senderId,
            file,
            url,
            templatetext 
          }
        }
        isMMS={props.isMMS}
    />
    </div>
  );
}
