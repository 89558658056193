import React, {useEffect} from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import { getToken, getUser, saveUser } from "../../../Utils/Common/index.js";
import Constants from "../../../Utils/Constants/index.js";
import toastMaker from "../../../Common/toastmaker.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledExpansionPanels() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const settings = [
     {
      link: '#',
      lbl: 'Australia',
      text: 'No set-up required'
    },
    {
      link: 'https://docs.google.com/forms/d/1UgRtUicm3YhWi9DPI3TbR-uYcbUM1ZRTYpzxBWjSCQ8/edit',
      lbl: 'UK',
      text: 'UK Form'
    },
    {
      link: 'https://docs.google.com/document/d/1HHp_43vn4puU4DJyu86orXTr3OPqMlxIU9mufI_zdjI/edit?usp=sharing',
      lbl: 'USA/CANADA',
      text: 'USA/CANADA: 10DLC Form'
    }
  ]
  

  useEffect(() => {
    load()
  }, [])

  const load = () => {}

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  return (
    <ExpansionPanel
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography className={classes.heading}>
          <span className="mb-10"><b>Country Center</b></span>
        </Typography>
        <Typography className={classes.secondaryHeading}>
          SMS settings as per country
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: "block" }}>
        <ul style={{ marginLeft: "40px" }}>
          {
            settings?.map((setting, i) => (<li>
              <Typography className={classes.heading}>
                {setting.lbl}: <a target="_blank" href={setting.link} >{ setting.text }</a>
              </Typography>
            </li>))
          }
          
        </ul>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
