import { BrowserRouter, HashRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { removeUserSession, getToken } from "./Utils/Common";
import { SnackbarProvider, useSnackbar } from "notistack";
import axios from "axios";
import logger from "./logger";
import React from "react";
import toastMaker from "./Common/toastmaker";

import Constants from "./Utils/Constants";
import Drawer from "./Drawer";
import ForgotPassword from "./ForgotPassword";
import Home from "./Home";
import Login from "./Login";
import Quickview from "./Quickview/index1";
import PrivateRoute from "./Utils/PrivateRoute";
import ResetPassword from "./ResetPassword";
import Signup from "./Signup";
import Unsubscribe from "./Unsubscribe";
import { getHeaders } from "./Utils/Common";
import store from "./Common/storage";
import environment from "./environments";

import "./App.css";

function App(props) {
  const { enqueueSnackbar } = useSnackbar();
  toastMaker.makeToast = enqueueSnackbar;

  const history = useNavigate();

  Constants.navigate = url => history(url, { shallow: true })

  Constants.api = {
    private: () => {
      let headers = getHeaders();
      let ret = {}

      ret.get = (path, params) => {
            return axios.get(`${path}?${params}`, headers)
      }
      return ret
    },
    public: () => {},
    login: (username, password) => {
        return axios.post(Constants.loginApiPath, {
          username: username,
          password: password,
          client_id: '2',
          client_secret: 'ur7r3um3W9YwFM3IMpqFpUdcBUnbEJvWLiV8snmt',
          grant_type: 'password'
        },
        { headers: {} })
    }
  }

  Constants.getApiPathWithConfigId = path => {
    return path.replace('{c_id}', store.get('c_id'))
  }

  Constants.getAcUrl = () => {
    return store.get('user').configs[0].host
                .replace('api-us1.com', 'activehosted.com')
  }

  Constants.getAcContactUrl = id => {
    return Constants.getAcUrl() + `/app/contacts/${id}`
  }

  Constants.unsubscribe_link_show = `http://vc1.me/c/{code}`

  Constants.unsubscribe_link = environment.UNSUBSCRIBE_UI.replace('{c_id}', store.get('c_id')).replace('{ac_contact_id}','{{code}}')

  logger.log("App.css loaded");

  axios.interceptors.response.use(
    (response) => {
      console.log(`axios intercepted response ${response}`);
      return response;
    },
    (error) => {
      logger.log("Error intercepted..", error, JSON.stringify(error));
      if (error && error.response && (error.response.status === 401 || error.response.status === 500)) {
        logger.log("Unauthorized need a logout");
        if (getToken()) {
          removeUserSession();
          window.location.assign(process.env.PUBLIC_URL + "/");
        }
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );

  return (
    <div className="App">
      {/* <BrowserRouter /*basename={Constants.basepath} > */}
        <div className="content">
          <Routes>
            <Route exact path={Constants.home} element={<Signup />} />
            <Route exact path={Constants.login} element={<Login />} />
            <Route exact path={Constants.quickview} element={<Quickview />} />
            <Route
              exact
              path={Constants.forgotpassword}
              element={<ForgotPassword />}
            />
            <Route
              exact
              path={Constants.resetpassword}
              element={<ResetPassword />}
            />
            <Route exact path={Constants.signup} element={<Signup />} />
            <Route exact path={Constants.unsubscribe} element={<Unsubscribe />} />
            <Route exact path={Constants.unsubscribeshort} element={<Unsubscribe />} />
            <Route
              exact
              path={Constants.unsubscribe}
              element={<Unsubscribe />}
            />
            {/* <PrivateRoute
              path={Constants.dashboard}
              component={() => <Drawer />}
            /> */}
            <Route
              path={Constants.dashboard}
              element={<Drawer />}
            />
            <Route
              path={`${Constants.dashboard}/*`}
              element={<Drawer />}
            />
            <Route element={<Home />} />
          </Routes>
        </div>
      {/* </BrowserRouter> */}
    </div>
  );
}

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={3}>
      <App />
    </SnackbarProvider>
  );
}
