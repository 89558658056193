import React from "react";
import logger from "./../../../logger";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";

import Box from "@material-ui/core/Box";
import axios from "axios";
import Constants from "./../../../Utils/Constants";
import LoadingProgress from "./../../../Common/LoadingProgress";
import { getToken } from "./../../../Utils/Common";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#0D2645",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  previewcard: {
    maxWidth: 345,
    margin: "15px",
    display: "grid",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [logMap, setLogMap] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    campaignId,
    selectedlistcontacts,
    sms_senderid,
    sms_template,
  } = props;

  const getHeaders = () => {
    return {
      headers: { Authorization: `Bearer ${getToken()}` },
    };
  };

  const refreshData = () => {
    setIsLoading(true);
    const config = getHeaders();
    config.params = {
      campaignId,
    };
    const requestTwo = axios.get(Constants.campaignsmssendlog, config);
    return axios
      .all([requestTwo])
      .then(
        axios.spread((...responses) => {
          const responseHistory = responses[0];
          const newLogMap = {};
          responseHistory.data.data.campaignsmssendlog.forEach((log) => {
            newLogMap[log.contactjson.id] = log;
          });
          setLogMap(newLogMap);
          logger.log("campaignsmssendlog", responseHistory.data.data);
          setIsLoading(false);
        })
      )
      .catch((errors) => {
        console.error("error:", errors);
        setIsLoading(false);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
    refreshData();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button
        type="button"
        onClick={handleClickOpen}
        className="c-button c-button_primary"
      >
        View SMS Send Log
      </button>
      {isLoading && <LoadingProgress />}
      {!isLoading && (
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Campaign SMS Send Log
              </Typography>
              <Button autoFocus color="inherit" onClick={refreshData}>
                Refresh
              </Button>
            </Toolbar>
          </AppBar>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="left"
            alignItems="center"
          >
            {selectedlistcontacts &&
              selectedlistcontacts.map((contact) => {
                const log = logMap[contact.id];
                const status = (log && log.status) || "Pending";
                const style = {};
                if (status === "Done") {
                  style.backgroundColor = "lightgreen";
                } else if (status === "Pending") {
                  style.backgroundColor = "lightcoral";
                }
                logger.log(log);
                return (
                  <Card
                    className={classes.previewcard + " speech-bubble"}
                    style={style}
                  >
                    <CardActionArea style={{ userSelect: "text" }}>
                      <CardContent>
                        <Typography gutterBottom variant="body2">
                          <sup>From:</sup>
                          {(log && log.sms_senderid) || sms_senderid}
                        </Typography>
                        <Typography gutterBottom variant="body2">
                          <sup>To:</sup>
                          {contact.firstName} {contact.lastName} (
                          {contact.phone})
                        </Typography>
                        <Typography gutterBottom variant="body2">
                          <sup>Status:</sup>
                          {status}
                        </Typography>
                        <Typography gutterBottom variant="body2">
                          <sup>SMS:</sup>
                        </Typography>
                        <div className="speech-bubble">
                          {(log && log.sms_content) || sms_template}
                        </div>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                );
              })}
          </Box>
        </Dialog>
      )}
    </div>
  );
}
