import React from "react";
import './index.css';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Box from "@mui/material/Box";


export default function MobilePreview(props) {

  const getCurrentDate = () => {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      const now = new Date();
      const dayName = days[now.getDay()];
      const day = String(now.getDate()).padStart(2, '0');
      const month = months[now.getMonth()];
      const year = now.getFullYear();

      return `${dayName}, ${day} ${month} ${year}`;
  }

  const getCurrentTime = () => {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // '0' should be '12'

    // Add leading zeros to minutes if necessary
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${hours}:${minutes} ${ampm}`;
}
  
  return (
    <>
      <div className="smartphone">
        <div className="content">
          <div className="inner">
            <Grid container spacing={0}>
              <Grid item xs={1}>
                <ArrowBackIosIcon />
              </Grid>
              <Grid item xs={2}>
                  <AccountCircleIcon />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h6">
                  {
                    props?.bulkSMSConfig?.senderId ?? 'Sender ID'
                  }
                </Typography>
              </Grid>
              <Grid item xs={1}>
                  <DeleteForeverIcon />
              </Grid>
            </Grid>
            <Divider/>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >

            </Grid>
            <Box className={''}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 1 }}
            >
              <Chip label={getCurrentDate()} />

            </Box>
            
            <div className="msgcontainer">
                <div className="sorted">
                    {
                      props?.bulkSMSConfig?.subject && props?.isMMS &&
                      <p className="msg">
                        {props?.bulkSMSConfig?.subject}
                      </p>
                    }
                    {
                      props?.bulkSMSConfig?.url && props?.isMMS &&
                      <img src={props?.bulkSMSConfig?.url} width="210px" style={{marginBottom: '10px'}} />
                    }
                    <p className="msg">
                      {props?.bulkSMSConfig?.templatetext ? props?.bulkSMSConfig?.templatetext : 'Message goes here'}
                    </p>
                </div>
                <div className="sorted time">
                    <p>{getCurrentTime()}</p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
      );
}
