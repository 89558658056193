import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SelectedListItem(props) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index, listitem) => {
    setSelectedIndex(index);
  };

  return (
    <div className={classes.root}>
      <List
        aria-label="secondary mailbox folder"
        style={{ maxHeight: 400, overflow: "auto" }}
      >
        {props.contacts &&
          props.contacts.map((listitem, index) => {
            return (
              <ListItem
                key={listitem.id}
                button
                selected={selectedIndex === index}
                onClick={(event) => handleListItemClick(event, index, listitem)}
              >
                <ListItemText
                  primary={listitem.firstName + " " + listitem.lastName}
                  secondary={listitem.phone}
                />
              </ListItem>
            );
          })}
      </List>
    </div>
  );
}
