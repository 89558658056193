import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ToolTip from "@material-ui/core/ToolTip";
import HelpIcon from '@material-ui/icons/Help';
import toastMaker from "./../../../Common/toastmaker";
import Switch from '@material-ui/core/Switch';
import "./index.css";
import Constants from './../../../Utils/Constants';
import Typography from "@material-ui/core/Typography";


const CHARACTER_LIMIT = 160;

const customContentStyle = {
  width: "100%",
};

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [templatetext, setTemplatetext] = React.useState(
    "Type your SMS message here.\nFor marketing message compliance, you can use the optout hyperlink at the end of this message as it is. (http://vc1.me/c/{code})"
  );
  const [name, setName] = React.useState("");
  const [senderId, setSenderId] = React.useState("");
  const [isMMS, setIsMMS] = React.useState(false);
  const [subject, setSubject] = React.useState("");
  const [file, setFile] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
    //logger.log("Open is:" + open);
  };

  const handleClose = () => {
    setOpen(false);
    setTemplatetext("");
    setName("");
    setSenderId("");
    setSubject("");
    setFile("");
  };

  const handleSubmit = () => {
    if (
      !templatetext ||
      templatetext.length === 0 ||
      !name ||
      name.length === 0 ||
      !senderId ||
      senderId.length === 0
    ) {
      toastMaker.makeToast("All fields are mandatory!", {
        variant: "error",
      });
      return;
    }
    // if (senderId.length > 11) {
    if (senderId.length > 15) {
      toastMaker.makeToast(
        "Sender Id should be less than or equal to 15 characters.",
        {
          variant: "error",
        }
      );
      return;
    }
    setOpen(false);
    props.onAddTemplateSubmit({
      template_text: templatetext,
      template_name: name,
      sender_id: senderId,
      subject: subject,
      isMMS: isMMS,
      file: file
    });
    setTemplatetext("");
    setName("");
    setSenderId("");
    setSubject("");
    setFile("");
  };

  return (
    <div>
      <ToolTip title="Add new SMS Template">
        <div id="wrap-btn">
          <input
            id="plan-btn"
            type="button"
            value="Add a template"
            onClick={handleClickOpen}
          />
        </div>
      </ToolTip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="modal-main">
          <DialogTitle id="form-dialog-title">
            <div className="modal-title h4">Add Template</div>
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">x</span>
            </button>
          </DialogTitle>
          <DialogContent id="form-dialog_body">
            <form>
                <label>
                  Choose type of template
                  <div class="tooltip ml-2">
                      <HelpIcon />
                      <span class="tooltiptext">
                        MMS includes a subject line and an image—a powerful marketing tool for premium promotions sent to your contacts.
                      </span>
                    </div>
                </label>
                <div className="form-groups">
                  SMS<Switch
                    checked={isMMS}
                    onChange={() => setIsMMS(!isMMS)}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />MMS
                </div>
              <label>Name</label>
              <div className="form-groups">
                <input
                  type="text"
                  id="formName"
                  className="form-controls"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </div>

              <label>Sender Id</label>
              <div className="form-groups">
                <input
                  type="text"
                  id="formSenderId"
                  className="form-controls"
                  value={senderId}
                  onChange={(event) => {
                    setSenderId(event.target.value);
                  }}
                />
              </div>

              {
                isMMS && (<>
                  <label>Subject</label>
                  <div className="form-groups">
                    <input
                      type="text"
                      id="formSubject"
                      className="form-controls"
                      value={subject}
                      onChange={(event) => {
                        setSubject(event.target.value);
                      }}
                    />
                     <label>File*</label>
                      <div className="form-groups">
                        <input
                          id="file"
                          onChange={(event) => {
                            setFile(event.target.files);
                          }}
                          type="file"
                          className="form-controls"
                          accept=".jpg, .jpeg, .png, .gif"
                        />
                        {file && <img src={URL.createObjectURL(file[0])} width={200} className="mt-3" />}
                      </div>
                      <Typography style={{ color: "red" }}>
                        *For optimal MMS image quality, utilize JPG format within 500 KB in size. Adjust your image to 640 pixels x 1,138 pixels with a 9:16 aspect ratio for ideal display. Alternatively, consider using 1080 px by 1920 px for your standard image files.
                      </Typography>
                  </div>
                </>)
              }
              Unsubscribe link: {Constants.unsubscribe_link_show}
              <label>SMS Template</label>
              <div className="form-groups">
                <textarea
                  rows="4"
                  id="formSmsTemplate"
                  className="form-controls"
                  value={templatetext}
                  onChange={(event) => {
                    setTemplatetext(event.target.value);
                  }}
                  inputProps={{
                    maxLength: CHARACTER_LIMIT,
                  }}
                  helperText={`${templatetext.length}/${CHARACTER_LIMIT}`}
                />
              </div>
              <div className="text-limit">{`${
                templatetext.length
              }/${CHARACTER_LIMIT}`}</div>
            </form>
          </DialogContent>
          <DialogActions id="dialog-footer">
            <button
              type="button"
              onClick={handleClose}
              className="c-button c-button_primary"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="c-button c-button_secondary"
            >
              Add
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
