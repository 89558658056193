import React from "react";
import Icon from "./../../../../assets/components/icon";

export default function (props) {
  return (
    <div className="c_layout-feature__item">
      <Icon name={"mobile-screen"} />
      <h5>{props.heading}</h5>
      <p>{props.description}</p>
      <a href="#" className="arrow-link">
        <Icon name={"go-to-arrow"} />
      </a>
    </div>
  );
}
