import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LoadingProgress from "./../../../Common/LoadingProgress";
import { getToken, getUser, saveUser, getHeaders } from "./../../../Utils/Common";
import { Input } from "@material-ui/core";

import ToolTip from "@material-ui/core/ToolTip";
import edit from "./edit.svg";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import Constants from "./../../../Utils/Constants/index"
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 450,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ControlledExpansionPanels() {
  const user = getUser();
  
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen]           = React.useState(false)
  const [virtualNumbers, setVirtualNumbers] = React.useState(
    user.configs[0].longcodes
  );
  const [settings, setSettings] = React.useState()
  const [settingsReply, setSettingsReply] = React.useState()
  const [settingsDate, setSettingsDate] = React.useState()
  const [settingsReplySelect, setSettingsReplySelect] = React.useState()
  const [settingsDateSelect, setSettingsDateSelect] = React.useState()
  const [customFields, setCustomFields] = React.useState()
  
  const handleDialogClose = () => setOpen(false)
  const handleDialogOpen  = () => setOpen(true)
  
  const handleDialogSubmit = () => {
    if(settingsReplySelect && settingsDateSelect) {
      axios.post(Constants.getApiPathWithConfigId(Constants.postACCustomFieldsApiPath), 
      {
        reply_field_id: settingsReplySelect,
        date_field_id: settingsDateSelect
      },
      getHeaders())
      .then(response => {
        handleDialogClose()
        load()
      })
      .catch(error => console.error(error))
    } else {
      handleDialogClose()
    }
  }
    

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const load = () => {
    axios.get(Constants.getApiPathWithConfigId(Constants.getSettingsApiPath), getHeaders())
         .then(response => {
          if(response?.data?.data != undefined && response?.data?.data?.length) {
            setSettings(response.data.data)
            let reply = response?.data?.data?.filter(e => e.key == "sms_setting_2way_reply_custom_field_id")[0].value 
            setSettingsReply(reply)
            setSettingsReplySelect(reply)
            let date = response.data.data?.filter(e => e.key == "sms_setting_2way_date_custom_field_id")[0].value
            setSettingsDate(date)
            setSettingsDateSelect(date)
          }
          })
         .catch(error => console.error(error))

    axios.get(Constants.getApiPathWithConfigId(Constants.getACCustomFieldsApiPath), getHeaders())
        .then(response => setCustomFields(response.data.data))
        .catch(error => console.error(error))
  }

  React.useState(() => {
    load()
  }, [])

  if (isLoading) {
    return <LoadingProgress />;
  }

  if (!user.configs[0].longcodes.length) {
    return null;
  }

  return (
    <ExpansionPanel
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography className={classes.heading}>
          <span className="mb-10"><b>Two Way SMS Numbers</b></span>
        </Typography>
        <Typography className={classes.secondaryHeading}>
          View configured Virtual Numbers for 2 Way SMS
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: "block" }}>
        {virtualNumbers &&
          virtualNumbers.map((config) => {
            return (
              <tr>
                <td style={{ width: "100%" }}>
                  <div style={{ padding: "10px" }}>
                    <Typography style={{ fontWeight: "bold", wordBreak: "break-all" }}>
                      {config.number}
                    </Typography>
                    <Typography >
                      Save Reply to: {customFields?.filter(e => e.id == settingsReply)[0]?.title || '-'}
                    </Typography>
                    <Typography >
                    Save Reply date to: {customFields?.filter(e => e.id == settingsDate)[0]?.title || '-'}
                    </Typography>
                  </div>
                </td>
                <td style={{ width: "100%" }}>
                  <div style={{ padding: "10px" }}>
                    <div style={{ display: "flex" }}>
      <ToolTip title="Edit Config">
        <img
          src={edit}
          alt="edit"
          onClick={() => {
            handleDialogOpen();
          }}
        />
      </ToolTip>
      {/* <ToolTip title="Delete Config">
        <img
          src={certificate}
          alt="certificate"
          onClick={() => {
            const result = window.confirm(
              "Are you sure you want to delete this config? This action is irreversible."
            );
            if (!result) {
              return
            }
            props.onDeleteTemplateSubmit(props.row);
          }}
        />
      </ToolTip> */}

      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="modal-main">
          <DialogTitle id="form-dialog-title">
            <div className="modal-title h4">
              Edit Two Way SMS field mapping 
            </div>
            <button
              type="button"
              className="close"
              onClick={handleDialogClose}
              style={{ marginLeft: "0px" }}
            >
              <span aria-hidden="true">x</span>
            </button>
          </DialogTitle>
          <DialogContent id="form-dialog_body">
            <form>
              <label>Save reply to</label>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  native
                  value={settingsReplySelect}
                  onChange={(event) => {setSettingsReplySelect(event.target.value)}}
                  inputProps={{
                    name: 'save-reply-to',
                    id: 'save-reply-to',
                  }}
                >
                <option aria-label="None" value="" />
                 {
                  customFields?.map(element => <option value={element.id}>{element.title}</option>)
                 }
                </Select>
              </FormControl>

              <label>Save reply date to</label>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  native
                  value={settingsDateSelect}
                  onChange={(event) => {setSettingsDateSelect(event.target.value)}}
                  inputProps={{
                    name: 'save-reply-date-to',
                    id: 'save-reply-date-to',
                  }}
                >
                <option aria-label="None" value="" />
                 {
                  customFields?.map(element => <option value={element.id}>{element.title}</option>)
                 }
                </Select>
              </FormControl>

            </form>
          </DialogContent>
          <DialogActions id="dialog-footer">
            <button
              type="button"
              onClick={() => {
                handleDialogClose()
                load()
              }}
              className="c-button c-button_primary"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleDialogSubmit}
              className="c-button c-button_secondary"
            >
              Save
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
                  </div>
                </td>
              </tr>
            );
          })}
      </ExpansionPanelDetails>
    </ExpansionPanel>
    
  );
}
