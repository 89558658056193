import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { useParams } from 'react-router-dom';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import Constants from "../Utils/Constants/index"
import axios from "axios";

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
} from "@chatscope/chat-ui-kit-react";

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@mui/material/MenuItem';

import "./index.css";

const useStyles = makeStyles({
    root: {
      width: "100%",
    },
    container: {
      maxHeight: "100%",
    },
  });

function Quickview(props) {

    const classes = useStyles();
    const params                            = useParams();
    const [longcode, setLongcode]           = useState('')
    const [longcodes, setLongcodes]         = useState([])
    const [messages, setMessages]           = useState([])
    const [messageInputValue, setMessageInputValue] = useState('')
    let timer = null

    const changeLongcode = v => {
        let c = longcodes.filter(f => f.number == v)[0]
        let m = longcodes.map(m => m.number)
        let i = m.indexOf(c.number)
        params.embed == '1' ? loadQV(i) : load(i)
    }
   
    useEffect(() => {
        if(params.embed == '1') {
            window.zoid.create({
                tag: "SMS Conversation", //This tag must match the Quick View Name in App Studio.
                url: window.location.href
            });
            loadQV(0)
        } else {
            load(0)
        }

        return () => {
            clearInterval(timer);
        };

    }, [])

    const getValue = key => params.embed == '1' ? window.xprops.data.contact[key] : getQueryVariable(key)
    
    const load = (longcode_index) => {
        if(timer)
            clearInterval(timer);

        let url = 'https://{subdomain}.api-us1.com'.replace('{subdomain}', getQueryVariable('configuration').split('.')[0]);

        url = Constants.configurationsListApiPath + `?host=${url}`;

        axios.get(url)
          .then(response => {
                response = response.data.data[0]                
                let lcs  = response.longcodes
                let lc   = response.longcodes[longcode_index]
                setLongcodes(lcs)
                setLongcode(lc)
                Constants.lc = lc
                loadMessages()
                
                timer = setInterval(() => loadMessages(), 10000);
            })       
          .catch(error => console.error(error))
    }

    const loadQV = async(longcode_index) => {
        if(timer)
            clearInterval(timer);
        let url = window.xprops.connection_data[0].ac.account_name
        url = 'https://{subdomain}.api-us1.com'.replace('{subdomain}', url.split('.')[0]);

        url = Constants.configurationsListApiPath + `?host=${url}`;

        const connectionId = window.xprops.connection_data[0].id;
        const target = "external";

        clearInterval(timer);

        let response = await window.xprops.getDataViaProxy(url, connectionId, target);
        
        response = response.data.data.body.data[0]

        let lcs  = response.longcodes
        let lc   = response.longcodes[longcode_index]
        setLongcodes(lcs)
        setLongcode(lc)
        Constants.lc = lc
        loadMessagesQA()
        timer = setInterval(() => loadMessagesQA(), 5000); //number, 
    }

    const loadMessages = () => {  
        let url = Constants.virtualnumbermsgs.replace('{c_id}', Constants.lc?.config_id) + `/${Constants.lc?.number}/${getQueryVariable('phone')}`;
        axios.get(url)
          .then(response => {
                response = response.data.data
                setMessages(response)
            })       
          .catch(error => console.error(error))
    }

    const loadMessagesQA = async () => {
        
        let url = Constants.virtualnumbermsgs.replace('{c_id}', Constants.lc.config_id) + `/${Constants.lc.number}/${window.xprops.data.contact.phone}`;
        
        const connectionId = window.xprops.connection_data[0].id;
        const target = "external";

        let response = await window.xprops.getDataViaProxy(url, connectionId, target);
        // console.warn('Response2|VYBRNT', response);
        response = response.data.data.body.data
        setMessages(response)
    }

    const getQueryVariable = (variable) => {
            var query = window.location.search.substring(1);
            if(query != undefined) {
                var vars = query?.split("&");
                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split("=");
                    if(pair[0] == variable) {
                        return pair[1];
                    }
                }
            }
            
            return(false);
    }

    const sendReply = async message => {
        let url = Constants.virtualnumbermsgs.replace('{c_id}', longcode.config_id) + `/${longcode.number}/${window.xprops.data.contact.phone.replace('+','')}`;

        const connectionId = window.xprops.connection_data[0].id;
        
        const target       = "external";
        const body         = {
            number: window.xprops.data.contact.phone.replace('+',''),
            ac_id: window.xprops.data.contact.id,
            virtual_number: longcode.number,
            message: message,
            type: 'outgoing'
        };
        setMessageInputValue("")
        
        let response = await window.xprops.postDataViaProxy(url, connectionId, target, body);
    }

    return longcode == '' ? <p>Please configure 2-way-sms</p> : (
        <div className="conversation-section">
        <Paper className={classes.root}>
          <div
            style={{
              height: "370px",
              position: "relative",
            }}
          >              
            <MainContainer responsive>
              <ChatContainer id="chatcontainer-4874">
              <ConversationHeader>                
                  
                  <ConversationHeader.Content>
                    <div>
                        <div className="col-left">
                            <p className="user-name">
                                {`${getValue('firstName')} ${getValue('lastName')}`}
                            </p>
                            <p className="reference-no">
                                {getValue('phone')}
                            </p>
                        </div>
                        <div className="col-right">
                            <Stack>
                            <div className="icon-btn">
                                <select onChange={e => changeLongcode(e.target.value)}>
                                {
                                    longcodes.map(l => (
                                    <option value={l.number}
                                    >{l.number}</option>)
                                    )
                                }
                                </select>
                                </div>
                            </Stack>
                        </div>
                    </div>
                    </ConversationHeader.Content> 
                  </ConversationHeader>
                <MessageList>
                    {
                        messages?.map(m => {
                            return <Message
                                model={{
                                message: m.message,
                                sentTime: "20 mins ago",
                                direction: m.type,
                                position: "single",
                            }}
                        >
                            <Message.Footer
                            sentTime={new Date(m.created_at).toLocaleString()}
                            />
                        </Message>
                        })
                    }

                </MessageList>
                <MessageInput
                  placeholder="Write a message"
                  value={messageInputValue}
                  onChange={(val) => setMessageInputValue(val)}
                  onSend={sendReply}
                  attachButton={false}
                />
              </ChatContainer>

              
            </MainContainer>
          </div>
        </Paper>
      </div>
    )
}

export default Quickview;

