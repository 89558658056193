import React from "react";
import axios from "axios";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Box from '@mui/material/Box';

import { useClipboard } from "use-clipboard-copy";

import Constants from "./../../Utils/Constants";
import LoadingProgress from "./../../Common/LoadingProgress";
import { getHeaders, getToken } from "./../../Utils/Common";
import toastMaker from "./../../Common/toastmaker.js";
import store from "./../../Common/storage";
import TagSection from "./../../Common/TagSectionTooltip";
import Induction from "./../../Common/Induction";
import Header from "./../../assets/components/header";

import ActionButtonWithMenu from "./ActionButtonWithMenu";
import AddTemplateButton from "./AddTemplateButton";
import AddTemplateTableButton from "./AddTemplateTableButton";

import searchIcon from "./search.svg";
import avatar from "./avatar.png";
import multipulFile from "./multipul-file.svg";

import Pagination from '@material-ui/lab/Pagination';

import "./index.css";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 16,
    fontWeight: 600,
    padding: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(251, 220, 119, 0.28)",
    },
  },
}))(TableRow);

const columns = [
  { id: "template_name", label: "Campaign Name", minWidth: 170, align: "left" },
  { id: "tags", label: "Labels", minWidth: 170, align: "center" },
  { id: "sender_id", label: "Sender ID", minWidth: 170, align: "left" },
  { id: "template_text", label: "Template", minWidth: 170, align: "center" },
  {
    id: "link",
    label: "Link",
    minWidth: 100,
    align: "left",
  },
  { id: "actions", label: "", minWidth: 100, align: "center" },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "100%",
  },
});

const CHARACTER_LIMIT = 160;

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [serverData, setServerData] = React.useState(store.get("templates"));
  const [namefilter, setNamefilter] = React.useState(null);
  const [labelfilter, setLabelfilter] = React.useState(null);
  const [contentfilter, setContentfilter] = React.useState(null);
  const [senderfilter, setSenderfilter] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(0);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const clipboard = useClipboard({
    onSuccess() {
      toastMaker.makeToast(
        `Great! Template has been copied successfully.`,        
        {
          variant: "success",
        }
      );
      //logger.log("Text was copied successfully!");
    },
    onError() {
      toastMaker.makeToast("'Failed to copy text!'", {
        variant: "error",
      });
      //logger.log("Failed to copy text!");
    },
  });

  const refreshData = () => { 
    axios.get(Constants.getApiPathWithConfigId(Constants.templatesApiPath) + `?per_page=20&page=${page}`, getHeaders())
                            .then(response => {
                              setPageCount(response.data.meta.last_page)
                              setServerData(response.data.data)
                            })
                            .catch(error => console.error(error))
  };

  React.useEffect(() => {
    refreshData();
  }, [page]);

  const onAddTemplateSubmit = (addData) => {
    // console.log(addData, 'rowrow');
    // return;
    const formData = new FormData();
    formData.append("template_text", addData.template_text);
    formData.append("template_name", addData.template_name);
    formData.append("sender_id", addData.sender_id);
    formData.append("subject", addData.subject);
    formData.append("file", addData.file[0]);
    formData.append("is_mms", addData.isMMS ? 1 : 0);
    
    axios.post(Constants.getApiPathWithConfigId(Constants.updateTemplateApiPath), formData, {        
      headers: { 
        "Authorization": `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data"
      }})
               .then((response) => {
                  refreshData();
                  toastMaker.makeToast("Added new template..", {
                          variant: "success",
                        });
              }).catch(e =>console.error(e));
  };

  const onDeleteTemplateSubmit = (row) => {
    axios.put(Constants.getApiPathWithConfigId(Constants.updateTemplateApiPath) + '/' + row.id, {status: 0}, getHeaders())
               .then((response) => {
                  refreshData();
                  toastMaker.makeToast("Deleted template..", {
                          variant: "success",
                        });
              }).catch(e =>console.error(e));
  };

  const onEditTemplateSubmit = async (row) => {
    if(row.is_mms && row.template_file) {
      const formData = new FormData();
      
      formData.append("file", row.template_file[0]);

      let data = await axios.post(Constants.getApiPathWithConfigId(Constants.createFileApiPath), formData, {        
        headers: { 
          "Authorization": `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data"
        }})
        
        row.file = data.data.data.url;
    } 
    
      axios.put(Constants.getApiPathWithConfigId(Constants.updateTemplateApiPath) + '/' + row.id, row, getHeaders())
                .then((response) => {
                    refreshData();
                    toastMaker.makeToast("Updated template..", {
                            variant: "success",
                          });
                }).catch(e =>console.error(e));
    // }
  };

  if (!serverData) {
    return <LoadingProgress />;
  }

  const SMSTableRow = (props) => {
    const { row } = props;
    const rowId = row.id;

    if (!row) {
      return (
        <StyledTableRow hover role="checkbox" tabIndex={-1} key={rowId}>
          <TableCell colSpan={columns.length}>
            <Skeleton />
            <Skeleton animation={false} />
            <Skeleton animation="wave" />
          </TableCell>
        </StyledTableRow>
      );
    }

    return (
      <StyledTableRow hover role="checkbox" tabIndex={-1} key={rowId}>
        {columns.map((column) => {
          if (column.id == "tags") {
            return (
              <StyledTableCell key={column.id} align={column.align}>
                <div style={{ textAlign: "center" }}>
                  <TagSection templateId={row.id} row={row} />
                </div>
              </StyledTableCell>
            );
          }
          const value = row[column.id];
          console.log(row, 'row')
          if (column.id == "template_text") {
            return (
              <StyledTableCell key={column.id} align={column.align}>
                <div className="template-info">
                  <div className="template-info__item">
                      <span>Type : </span>
                      <p className="">{row.is_mms == 1 ? 'MMS' : 'SMS'}</p>
                    </div>
                  {
                    row.is_mms == 1 ? <>
                    <div className="template-info__item">
                      <span>Subject : </span>
                      <p className="template-info__item--border">{row.subject}</p>
                    </div>
                    <div className="template-info__item">
                      <span>File : </span>
                      <img src={row.file} alt={row.subject} width={200} />
                    </div>
                  </> : <></>}
                  <div className="template-info__item">
                    <span>Message : </span>
                    <p className="template-info__item--border section-message">{value}</p>
                  </div>
                  <div className="template-info__item">
                    <span>Total Chars : </span>
                    <p>
                      {value.length} / {CHARACTER_LIMIT}
                    </p>
                  </div>
                </div>
              </StyledTableCell>
            );
          } else if (column.id === "link") {
            const templatePostEndpoint =
              Constants.sendSMSApiPath + "?id=" + row.id;
            const value = row["template_text"];
            return (
              <StyledTableCell key={column.id}>
                <button
                  className="c-button btn-icon"
                  onClick={() => {
                    if (clipboard.isSupported()) {
                      clipboard.copy(value);
                    } else {
                      toastMaker.makeToast(
                        "Copy to clipboard not supported by browser.",
                        {
                          variant: "error",
                        }
                      );
                    }
                  }}
                >
                  <img src={multipulFile} alt="multipul-file" />
                  Copy Template
                </button>
              </StyledTableCell>
            );
          } else if (column.id === "actions") {
            return (
              <StyledTableCell>
                <div className="o-icon">
                  <ActionButtonWithMenu
                    row={row}
                    onDeleteTemplateSubmit={onDeleteTemplateSubmit}
                    onEditTemplateSubmit={onEditTemplateSubmit}
                  />
                </div>
              </StyledTableCell>
            );
          } else if (column.id === "template_name") {
            return (
              <StyledTableCell component="th" scope="row">
                <div className="userinfo">
                  <img alt="profile" src={avatar} />
                  <span>
                    {column.format && typeof value === "number"
                      ? column.format(value)
                      : value}
                  </span>
                </div>
              </StyledTableCell>
            );
          } else {
            return (
              <StyledTableCell
                className="text-blue"
                key={column.id}
                align={column.align}
              >
                {column.format && typeof value === "number"
                  ? column.format(value)
                  : value}
              </StyledTableCell>
            );
          }
        })}
      </StyledTableRow>
    );
  };

  let filteredData = serverData?.filter((row) => {
    let filterCriteria = namefilter;
    let filterText = row.template_name || "";
    if (filterCriteria && filterCriteria.trim().length > 0) {
      if (filterText.indexOf(filterCriteria) === -1) {
        return false;
      }
    }
    filterCriteria = senderfilter;
    filterText = row.sender_id || "";
    if (filterCriteria && filterCriteria.trim().length > 0) {
      if (filterText.indexOf(filterCriteria) === -1) {
        return false;
      }
    }
    filterCriteria = contentfilter;
    filterText = row.template_text || "";
    if (filterCriteria && filterCriteria.trim().length > 0) {
      if (filterText.indexOf(filterCriteria) === -1) {
        return false;
      }
    }
    filterCriteria = labelfilter;
    filterText = row.tags || "";
    if (filterCriteria && filterCriteria.trim().length > 0) {
      if (filterText.indexOf(filterCriteria) === -1) {
        return false;
      }
    }
    return true;
  });

  let showTable =
    serverData &&
    serverData.length > 0;

  return (
    <div>
      <Grid container spacing={2}>
      <Grid item xs={12}>
      <Header name={"Sms Templates"} />
      <Induction
        name={props.name}
        content={
          <ul style={{ listStyleType: "circle" }}>
            <li className="ml-20">
              <p>
               Click on the "ADD TEMPLATE" button to start.
              </p>
            </li>
          </ul>
        }
      />
      </Grid>
      </Grid>
      {/* <AddTemplateButton onAddTemplateSubmit={onAddTemplateSubmit} /> */}
      {!showTable && (
        <div className="mb-100">
          <h3 style={{}}>
            You do not have any templates configured yet! Click on the button
            below to get started and add your first!
          </h3>
          <AddTemplateTableButton onAddTemplateSubmit={onAddTemplateSubmit} />
        </div>
      )}
      {showTable && (
        <Grid container spacing={2}>
        <Grid item xs={12}>    
          <Box className="mui-box" >            
            <Card>
              <div className="card-header--with-icon card-head col-search">
                <div className="item-flex search-box search-box-container">
                  <div className="input-group search-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img src={searchIcon} />
                      </span>
                    </div>

                    <input
                      type="text"
                      placeholder="Search Name"
                      className="input-box"
                      value={namefilter}
                      onChange={(event) => {
                        setNamefilter(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group search-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img src={searchIcon} />
                      </span>
                    </div>

                    <input
                      type="text"
                      placeholder="Search Sender"
                      className="input-box"
                      value={senderfilter}
                      onChange={(event) => {
                        setSenderfilter(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group search-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img src={searchIcon} />
                      </span>
                    </div>

                    <input
                      type="text"
                      placeholder="Search Label"
                      className="input-box"
                      value={labelfilter}
                      onChange={(event) => {
                        setLabelfilter(event.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group search-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img src={searchIcon} />
                      </span>
                    </div>

                    <input
                      type="text"
                      placeholder="Search Contents"
                      className="input-box"
                      value={contentfilter}
                      onChange={(event) => {
                        setContentfilter(event.target.value);
                      }}
                    />
                  </div>
                  <AddTemplateTableButton onAddTemplateSubmit={onAddTemplateSubmit} />
                </div>
                <div className="clear" />
              </div>
              <div className="card-body middle-container">
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <StyledTableRow id="table-head_rw">
                        {columns.map((column) => {
                          return (
                            <StyledTableCell
                              className="table-hd"
                              style={{ textAlign: column.align }}
                            >
                              {column.label}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData.map((row) => {
                        return <SMSTableRow row={row} key={row.id} />;
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {pageCount > 0 && <div className={`${classes.root} mt-3 d-flex justify-content-end`}>
                  <Pagination 
                    count={pageCount} 
                    page={page} 
                    onChange={handleChange} 
                    variant="outlined" 
                    shape="rounded"
                    />
                </div>}
              </div>
            </Card>
          </Box>
        </Grid>
        </Grid>
      )}
    </div>
  );
}
