import React from "react";
import Icon from "../../../assets/components/icon";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ToolTip from "@material-ui/core/ToolTip";

class ActiveCampaignForm extends React.Component {
  componentDidMount() {

    var elementExists = document.getElementById("script_form_45");
    if(!elementExists) {
      const script = document.createElement("script");
      script.src = "https://vybrnt.activehosted.com/f/embed.php?id=45";
      script.async = true;
      script.id    = 'script_form_45';
      document.body.appendChild(script);
    }
    
  }

  render() {
    return <div className="_form_45" />;
  }
}

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="feedback-section">
      <ToolTip title="Support/Strategy Portal">
        <Icon
          className="zoombutton"
          alt="logo"
          name={"question"}
          onClick={handleClickOpen}
        />
      </ToolTip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent id="id845764">
          <ActiveCampaignForm />
        </DialogContent>
      </Dialog>
    </div>
  );
}
