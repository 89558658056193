import React, { useState } from "react";
import logger from "./../../logger";
import axios from "axios";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import Constants from "./../../Utils/Constants";

import HomeLogo from "./../../Common/HomeLogo";
import HomeFooter from "./../../Common/HomeFooter";
import FeatureGrid from "./../../Common/FeatureGrid";

import "./index.scoped.css";

function Login(props) {
  const username = useFormInput("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // handle button click of login form
  const handleLogin = () => {
    logger.log("handleforgotpassword", props);
    setError(null);
    setLoading(true);
    axios
      .post(Constants.forgotpasswordApiPath, {
        email: username.value,
      })
      .then((response) => {
        setLoading(false);
        Constants.navigate(Constants.resetpassword);
      })
      .catch((error) => {
        logger.log("errors", error.response);
        setLoading(false);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          setError(error.response.data.errors[0]);
        } else {
          setError("Something went wrong. Please try again later.");
        }
      });
  };

  return (
    <div style={{}}>
      <div>
        <Grid container spacing={0}>
          <FeatureGrid />
          <Grid item md={6} xs={12} className="formcontent">
            <div className="loginMain">
              <HomeLogo />

              <div className="login-wrap">
                <h3 className="login-wrap_heading mb-30">forgot password</h3>
                <div style={{ color: "white" }}>
                  Please enter your account email below and click on "Reset
                  password" button.
                  <br />
                  You will receive further instructions to reset password.
                </div>
                <br />
                <div className="form-group" style={{ width: "100%" }}>
                  <input
                    placeholder="Enter Email"
                    className="login-textbx"
                    {...username}
                    type="text"
                    id="email"
                    name="email"
                    value={username.value}
                  />
                </div>

                <div className="action-link">
                  <Link
                    className="forgot-link"
                    style={{ textDecoration: "underline" }}
                    to={Constants.resetpassword}>
                      Already got a reset token? Enter here.
                  </Link>

                  <Link
                    className="forgot-link"
                    style={{ textDecoration: "underline" }}
                    to={Constants.login}>
                      Go back to login.
                  </Link>

                  <input
                    className="login-btn c-button"
                    type="button"
                    value="SEND PASSWORD RESET TOKEN"
                    onClick={handleLogin}
                    disabled={loading}
                  />
                </div>
                <div>
                    {error && (
                      <>
                          <small style={{ color: "red" }} dangerouslySetInnerHTML={{__html:error}}></small>
                        <br />
                      </>
                    )}
                  </div>
              </div>
              <HomeFooter />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default Login;
