import React from 'react';

import "./header.css"
import Credits from "../../Drawer/Credits/CreditsButtonForm"
import Upgrades from "../../Drawer/Credits/UpgradeButtonForm"

const Header = (({ name }) => (
  <header className="c-header">
   <h3 className="text-uppercase">{name}</h3>
   <div className="c-header__item">
     <Upgrades />
     <Credits/>
   </div>
 </header>
));

export default Header;
