const environment = {
  API_SCHEME: "https://",
  API_URL: "api-vybrntsms.vybrnt.com", //"api.vybrnt.com",
  API_CONTEXT: "/v1",
  STRIPE_KEY: "pk_live_fty7XSSQ285kCTe1qbqfdHvi",
  STRIPE_SUCCESSURL: "https://vybrntsms.vybrnt.com/dashboard",
  TWO_WAY_SMS_ENDPOINT: "https://api-vybrntsms.vybrnt.com/webhook/sms/incoming",
  UNSUBSCRIBE_UI: "https://vybrntsms.vybrnt.com/u/{c_id}/{ac_contact_id}",
};

export default environment