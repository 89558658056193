import React from "react";
//import { withRouter } from "react-router-dom";

import weblayout from "./../../assets/images/a-girl-with-laptop.png";

import "./index.css";

function MiniDrawer(props) {
  if (props.content) {
    return (
      <div className="c_layout-welcome">
        <div className="c_layout-welcome__img">
          <img src={weblayout} alt="Automation" style={{ width: "200px" }} />
        </div>
        <div className="c_layout-welcome__list">
          <div className="c_layout-welcome__item">
            <h3>Welcome {props.name ? props.name : ""}</h3>
            <span />
            {props.content}
          </div>
        </div>
      </div>
    );
  }
}

// export default withRouter(MiniDrawer);
export default MiniDrawer;
