let environment = require("./prod").default;

if(window.location.href.includes('localhost')) {
   environment = require("./local").default;  
}

if(window.location.href.includes('dev')) {
   environment = require("./dev").default;  
}

export default environment
