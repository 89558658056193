import React, { useEffect } from "react";
import logger from "./../logger";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import toastMaker from "./../Common/toastmaker";
import Button from "@material-ui/core/Button";
import Constants from "./../Utils/Constants";
import axios from "axios";
import { validate } from "react-email-validator";
import { useLocation, useParams } from 'react-router-dom';
import LoadingProgress from "../Common/LoadingProgress";
import "./index.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

const reasons = [
  "I am no longer interested",
  "Offer not interesting enough",
  "I receive messages too often",
  "I have no idea why I received it?",
];

export default function Unsubscribe(props) {
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [name, setName]   = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const classes = useStyles();
  const params = useParams();

  const unsubscribeCode = props?.match?.params?.unsubscribeCode;

  const [state, setState] = React.useState({});

  const addReason = (reasonname, text) => {
    setState({ ...state, [reasonname]: text });
  };

  const removeReason = (reasonname) => {
    const newState = { ...state };
    delete newState[reasonname];
    setState(newState);
  };

  logger.log("state", state);

  const { gilad, jason, antoine } = state;
  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

  const isEmailValidIfEntered = email && validate(email);

  useEffect(() => {
    console.log(params)
    if(params.ac_id !== undefined && params.ac_id !== 'undefined') {
      axios.get(Constants.getACContactDetailsById.replace('{c_id}', params.id?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")).replace('{ac_id}', params.ac_id?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")))
         .then(response => {
            console.log(response.data.data)
            setName(`${response.data.data.fname} ${response.data.data.lname}`)
            setEmail(response.data.data.email)
            setPhone(response.data.data.mobile)
            setIsLoading(false)
          })
         .catch(error => console.error(error))
    } else {
      setIsLoading(false)
    }
    
  }, [])

  return isLoading ? <LoadingProgress /> : (
    <div
      style={{ backgroundColor: "rgb(255, 255, 255)", padding: "40px" }}
      className="full-height"
    >
      <table id="optouttable" style={{borderCollapse: 'separate', borderSpacing: '0 20px'}}>
        <th colspan="2" >
          <td className="tablehead text-center d-block" style={{ lineHeight: '40px' }}>SMS Unsubscribe</td>
        </th>
        <tr>
          <td> Name: </td>
          <td>
              <input
                id="name"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                type="text"
                className="form-controls"
              />
            </td>
        </tr>

        <tr className="mt-2">
          <td> Phone No: </td>
          <td>
              <input
                id="phone"
                value={phone}
                onChange={(event) => {
                  setPhone(event.target.value);
                }}
                type="text"
                className="form-controls"
              />
          </td>
        </tr>

        <tr className="mt-2">
          <td> Email: </td>
          <td>
              <input
                id="email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                type="text"
                className="form-controls"
              />
            </td>
        </tr>
        {unsubscribeCode && false && (
          <>
            <tr>
              <td> Email: </td>
              <td>
                <div
                  className="form-groups"
                  style={{ width: "300px", paddingTop: "20px" }}
                >
                  <input
                    id="email"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    type="text"
                    className="form-controls"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                *Please enter your email so we can verify your mobile number
                against our database.
              </td>
            </tr>
            <tr>
              {isEmailValidIfEntered === false && (
                <td colSpan={2}>
                  <div
                    style={{
                      width: "300px",
                      paddingTop: "20px",
                      color: "red",
                      textAlign: "right",
                    }}
                  >
                    *Please enter a valid email id
                  </div>
                </td>
              )}
            </tr>
          </>
        )}
        <tr>
          <td> </td>
          <td>
            <FormControl
              required
              error={error}
              component="fieldset"
              className={classes.formControl}
            >
              {false && (
                <FormLabel component="legend">Pick any reasons</FormLabel>
              )}
              <FormGroup>
                {reasons.map((reason, index) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state["reason_" + index]}
                          onChange={(event) => {
                            logger.log(event.target.checked);
                            const checked = event.target.checked;
                            const key = event.target.name;
                            if (checked) {
                              addReason(key, reason);
                            } else {
                              removeReason(key);
                            }
                          }}
                          color="primary"
                          name={"reason_" + index}
                        />
                      }
                      label={reason}
                    />
                  );
                })}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        state["reason_other"] !== null &&
                        state["reason_other"] !== undefined
                      }
                      onChange={(event) => {
                        logger.log(event.target.checked);
                        const checked = event.target.checked;
                        const key = event.target.name;
                        if (checked) {
                          addReason(key, "");
                        } else {
                          removeReason(key);
                        }
                      }}
                      color="primary"
                      name={"reason_other"}
                    />
                  }
                  label={"Other (Please specify)"}
                />
                {state["reason_other"] !== null &&
                  state["reason_other"] !== undefined && (
                    <textarea
                      id="reason_other_details"
                      rows="4"
                      cols="50"
                      placeholder="Please help us with short details of why you wish to unsubscribe"
                      maxlength="255"
                      onChange={(event) => {
                        addReason("reason_other", event.target.value);
                      }}
                    />
                  )}
              </FormGroup>
              <Button
                id="submit_optout"
                variant="contained"
                color="primary"
                onClick={(event) => {
                  if (!phone) {
                    toastMaker.makeToast("Please enter your phone no", {
                      variant: "error",
                    });
                    return;
                  }
                  if (Object.keys(state).length == 0) {
                    toastMaker.makeToast("Select atleast one reason", {
                      variant: "error",
                    });
                    return;
                  }
                  if (unsubscribeCode && isEmailValidIfEntered === false) {
                    toastMaker.makeToast("Please enter a valid email id!", {
                      variant: "error",
                    });
                    return;
                  }
                  logger.log(state);
                  axios.post(Constants.unsubscribeApiPath.replace('{c_id}', params.id), {
                    ac_contact_id: params.ac_id,
                    number: phone,
                    email: email,
                    reason: JSON.stringify(state),
                  }).then(r => toastMaker.makeToast("Your number has been opted out of SMS messages successfully.", {
                    variant: "success",
                  }))
                  .catch(e => console.error(e))
                }}
              >
                Opt me out!
              </Button>
            </FormControl>
          </td>
        </tr>
      </table>
    </div>
  );
}
