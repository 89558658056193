const environment = {
  API_SCHEME: "https://",
  API_URL: "dev-api-sms.vybrnt.com", 
  API_CONTEXT: "/v1",
  STRIPE_KEY: "pk_test_M1tPrMD9I0wBXSgWCJOQNAZB",
  STRIPE_SUCCESSURL: "https://dev-sms.vybrnt.com/dashboard",
  TWO_WAY_SMS_ENDPOINT: "https://dev-api-sms.vybrnt.com/webhook/sms/incoming",
  UNSUBSCRIBE_UI:"https://dev-sms.vybrnt.com/u/{c_id}/{ac_contact_id}"
};

export default environment