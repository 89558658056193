import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LoadingProgress from "./../../../Common/LoadingProgress";
import { getToken, getUser, saveUser } from "./../../../Utils/Common";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const getHeaders = () => {
  return {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
};

export default function ControlledExpansionPanels() {
  const user = getUser();

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [publiccode, setPublicCode] = React.useState(user.publiccode);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (isLoading) {
    return <LoadingProgress />;
  }
console.log('user', user.configs[0].id)
  return (
    <ExpansionPanel
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography className={classes.heading}>
          <span className="mb-10"><b>Unsubscribe Link</b></span>
        </Typography>
        <Typography className={classes.secondaryHeading}>
          View your accounts unsubscribe link
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: "block" }}>
        <tr>
          <td style={{ width: "100%" }}>
            <div style={{ padding: "10px" }}>
              <Typography style={{ color: "grey", wordBreak: "break-all" }}>
                {`https://vybrntsms.vybrnt.com/u/${user.configs[0].id}`}
              </Typography>
              <Typography style={{ color: "blue" }}>
                *You can copy the above link into a url shortener like bit.ly
                and include it in the SMS
              </Typography>
            </div>
          </td>
        </tr>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
