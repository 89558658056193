import React from "react";
import logger from "./../../../../logger";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Constants from "./../../../../Utils/Constants";
import axios from "axios";
import { getHeaders, getToken } from "./../../../../Utils/Common";
import validator from "validator";
import LoadingProgress from "./../../../../Common/LoadingProgress";
import toastMaker from "./../../../../Common/toastmaker";

function SimpleDialog(props) {
  const { templatetext, senderId, subject, url, file } = props;

  const { onClose, open } = props;
  const [phoneNo, setPhoneNo] = React.useState("");
  const [isLoading, setIsLoading] = React.useState("");

  const handleClose = () => {
    onClose();
  };

  const handlePlanSelection = async () => {
    if (!phoneNo || phoneNo.length === 0) {
      toastMaker.makeToast("Please enter a phone no..", {
        variant: "error",
      });
      return;
    }
    const isValidPhoneNumber = validator.isMobilePhone(phoneNo);
    if (!isValidPhoneNumber) {
      toastMaker.makeToast("Enter a valid phone no..", {
        variant: "error",
      });
      return;
    }
    setIsLoading(true);

    let apiurl = props.isMMS ? Constants.getApiPathWithConfigId(Constants.sendTestMMSApiPath) : Constants.getApiPathWithConfigId(Constants.sendTestSMSApiPath)

    const formData = new FormData();
    formData.append("to", [phoneNo]);
    formData.append("message", templatetext);
    formData.append("from", senderId);
    formData.append("type", 'test');
   
    if(props.isMMS) {
      formData.append("file", file[0]);
      formData.append("subject", subject);
      formData.append("url", url);
    }
    
    await axios
      .post(apiurl, formData, 
        {        
          headers: { 
            "Authorization": `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data"
          },
        }).then(response => {
          toastMaker.makeToast(response.message || "Success", {
            variant: "success",
          });
        handleClose();
      })
      .catch(error => console.error(error))
    setIsLoading(false);
  };

  let dialogCOntent = (
    <div>
      <DialogTitle id="simple-dialog-title">
        Enter test phone number to send SMS
      </DialogTitle>
      <DialogContent>
        <div className="form-groups">
          <input
            id="campaignName"
            value={phoneNo}
            onChange={(event) => {
              setPhoneNo(event.target.value);
            }}
            type="text"
            className="form-controls"
          />
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "8px 24px 24px" }}>
        <span
          className="c-button text-uppercase"
          style={{ marginRight: "10px" }}
          onClick={handleClose}
        >
          Cancel
        </span>
        <span
          className="c-button c-button--secondary text-uppercase"
          onClick={handlePlanSelection}
        >
          Send Test SMS
        </span>
      </DialogActions>
    </div>
  );

  if (isLoading) {
    return <LoadingProgress />;
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        {dialogCOntent}
      </Dialog>
    </>
  );
}

export default function SimpleDialogDemo({ templatetext, senderId, subject, url, file, isMMS }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (
      senderId &&
      senderId.length > 0 &&
      templatetext &&
      templatetext.length > 0
    ) {
      setOpen(true);
    } else {
      toastMaker.makeToast(
        "Please ensure SenderId and Template Text are filled",
        {
          variant: "error",
        }
      );
      return;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <span
        className="c-button c-button--secondary text-uppercase"
        onClick={handleClickOpen}
      >
        Send a Test
      </span>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        templatetext={templatetext}
        senderId={senderId}
        subject={subject}
        url={url}
        file={file} 
        isMMS={isMMS}
      />
    </React.Fragment>
  );
}
