import React from "react";
import logger from "./../../logger.js";
import axios from "axios";
//import { withRouter } from "react-router-dom";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Switch from '@material-ui/core/Switch';
import Grid from "@material-ui/core/Grid";
import Box from '@mui/material/Box';

import Constants from "./../../Utils/Constants";
import toastMaker from "./../../Common/toastmaker.js";
import { getToken, getUser, getHeaders } from "./../../Utils/Common";
import Header from "./../../assets/components/header";
import Induction from "./../../Common/Induction";

import LoadingProgress from "./../../Common/LoadingProgress";
import ACListOfLists from "./ACListofLists";
import ACListOfSegments from "./ACListofSegments";
import ACListofContacts from "./ACListofContacts";
import BulkSMSForm from "./BulkSMSForm";
import ACListofConfigs from "./ACListofConfigs";
import CampaignSMSPreview from "./CampaignSMSPreview";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MobilePreview from "./MobilePreview/index.js";

import "./../../index.css"

// const getHeaders = () => {
//   return {
//     headers: { Authorization: `Bearer ${getToken()}` },
//   };
// };

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  instructionsDiv: {
    paddingTop: "30px",
  },
  stepper: {
    fontWeight: "600",
  },
  previewcard: {
    maxWidth: 345,
    margin: "15px",
    display: "grid",
  },
}));

function HorizontalLabelPositionBelowStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [campaignName, setCampaignName] = React.useState(null);
  const [campaignDescription, setCampaignDescription] = React.useState(null);
  const [lists, setLists] = React.useState([]);
  const [segments, setSegments] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isStepperLoading, setIsStepperLoading] = React.useState(false);
  const [showEnd, setShowEnd] = React.useState(false);
  const [selectedEnv, setSelectedEnv] = React.useState(null);
  const [selectedList, setSelectedList] = React.useState(null);
  const [selectedSegment, setSelectedSegment] = React.useState(null);
  const [contacts, setContacts] = React.useState([]);
  const [contactsMax, setContactsMax] = React.useState();
  const [bulkSMSConfig, setbulkSMSConfig] = React.useState({
    senderId: null,
    template: null,
    file: null,
    url: null,
    subject: null
  });
  const [useTwoWayNumber, setUseTwoWayNumber] = React.useState(false);
  const [selectedTwoWayNumber, setSelectedTwoWayNumber] = React.useState(null);

  const [isMMS, setIsMMS] = React.useState(false);

  const user = getUser();
// console.log(user)
  const isTwoWaySMSEnabled =
    user &&
    user?.configs &&
    user?.configs[0] &&
    user?.configs[0]?.longcodes &&
    user?.configs[0]?.longcodes?.length > 0;

  const contactsWithPhone = contacts.filter((contact) => {
    return contact.phone && contact.phone.trim().length > 0;
  });

  const stepData = {
    0: {
      title: "Environment",
      content: (
        <div className={classes.instructionsDiv}>
          <Typography className={classes.instructions}>
          Verify ActiveCampaign environment.
            <br /> If no environment is visible here, please configure in Settings {'>'} AC Config section
          </Typography>
          <ACListofConfigs
            configs={user.configs}
            setSelectedEnv={setSelectedEnv}
          />
        </div>
      ),
      next: () => {
        if (!selectedEnv) {
          toastMaker.makeToast(
            "Please select an ActiveCampaign Environment to proceed!",
            {
              variant: "error",
            }
          );
          return;
        }
        setIsStepperLoading(true);
        axios
          .get(Constants.getApiPathWithConfigId(Constants.aclistsApiPath), getHeaders())
          .then((resp) => {
            setLists(resp.data.data);
            setIsStepperLoading(false);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          })
          .catch((error) => {
            console.error("error:", error);
            setIsStepperLoading(false);
            toastMaker.makeToast("Some issue loading lists", {
              variant: "error",
            });
          });
      },
    },
    1: {
      title: "List",
      content: (
        <div className={classes.instructionsDiv}>
          <Typography className={classes.instructions}>
            Select an ActiveCampaign List
          </Typography>
          <Typography className={classes.instructions} style={{ color: "red" }}>
            * The list should contain atleast 1 and at the most 50,000 contacts
            with phone numbers.
          </Typography>
          <ACListOfLists lists={lists} setSelectedList={setSelectedList} />
        </div>
      ),
      next: () => {
        if (selectedList === null) {
          toastMaker.makeToast(
            "Please select a list before proceeding to next step.",
            {
              variant: "error",
            }
          );
          return;
        }
        setIsStepperLoading(true);
        axios
          .get(
            Constants.getApiPathWithConfigId(Constants.acsegmentsApiPath), getHeaders()
          )
          .then((resp) => {
            setSegments(resp.data.data.filter(e => e.name != ''));
            setIsStepperLoading(false);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          })
          .catch((error) => {
            console.error("error:", error);
            setIsStepperLoading(false);
            toastMaker.makeToast("Some issue loading contact data", {
              variant: "error",
            });
          });
      },
    },
    2: {
      title: "Segments",
      content: (
        <div className={classes.instructionsDiv}>
          <Typography className={classes.instructions}>
            Select an ActiveCampaign Segment
          </Typography>
          <Typography className={classes.instructions} style={{ color: "red" }}>
            * The segment should contain atleast 1 and at the most 50,000 contacts
            with phone numbers.
          </Typography>
          <ACListOfSegments segments={segments} setSelectedSegment={setSelectedSegment} />
        </div>
      ),
      next: () => {
        if (selectedSegment === null) {
          toastMaker.makeToast(
            "Please select a list before proceeding to next step.",
            {
              variant: "error",
            }
          );
          return;
        }
        setIsStepperLoading(true);
        axios
          .get(
            Constants.getApiPathWithConfigId(Constants.aclistContactsApiPath).replace('{l_id}', selectedList.id).replace('{s_id}', selectedSegment.id),
            getHeaders()
          )
          .then((resp) => {
            logger.log(resp.data, 'resp.data');
            setContacts(resp.data.data);
            setContactsMax(resp.data.meta.total);
            setIsStepperLoading(false);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          })
          .catch((error) => {
            console.error("error:", error);
            setIsStepperLoading(false);
            toastMaker.makeToast("Some issue loading contact data", {
              variant: "error",
            });
          });
      },
    },
    3: {
      title: "Contacts",
      content: (
        <div className={classes.instructionsDiv}>
          <Typography className={classes.instructions}>
            {/* Following contacts were found in the list. Please review before
            proceeding. Note that showing {contactsMax <= 100 ? (contactsMax == 1 ? `${contactsMax} contact` : `${contactsMax} contacts`) : `only 100 out of ${contactsMax} contacts`}  here, also contacts not having phone number will be
            skipped. */}

            There are {contactsMax} contacts found in the list/segment you have chosen.  The campaign will be sent to {contactsMax} contacts. 
            <br/>
            To help with clarity, following is a sample of 100 of these contacts. You can scroll through this list to cross reference them. 
          </Typography>
          <div style={{ fontWeight: 800, fontSize: "larger" }}>
            Total Contacts in list: ({contacts.length})
          </div>
          {/* <div style={{ fontWeight: 800, fontSize: "larger" }}>
            Contacts with phone number: ({contactsWithPhone.length})
          </div> */}
          <ACListofContacts contacts={contactsWithPhone} />
        </div>
      ),
      next: () => {},
    },
  };

  const getStepContent = (stepIndex) => {
    if (stepData[stepIndex]) {
      return stepData[stepIndex].content;
    } else {
      return "Unknown stepIndex";
    }
  };

  React.useEffect(() => {
    // console.log(bulkSMSConfig,'bulkSMSConfig')
  }, [bulkSMSConfig])

  const handleSubmitCampaign = () => {
    console.log(bulkSMSConfig)
    if(useTwoWayNumber && selectedTwoWayNumber) {
      bulkSMSConfig.senderId = selectedTwoWayNumber
    }
    
    if (!campaignName || campaignName.length === 0) {
      toastMaker.makeToast("Please enter a name for your campaign!", {
        variant: "error",
      });
      return;
    }
    if (
      !useTwoWayNumber &&
      (!bulkSMSConfig.senderId || bulkSMSConfig.senderId.length === 0)
    ) {
      toastMaker.makeToast("Sender Id is mandatory.", {
        variant: "error",
      });
      return;
    }
    if (
      !bulkSMSConfig ||
      !bulkSMSConfig.template ||
      bulkSMSConfig.template.length === 0
    ) {
      toastMaker.makeToast("SMS Template is mandatory.", {
        variant: "error",
      });
      return;
    }
    //if (bulkSMSConfig.senderId.length > 11) {
    if (bulkSMSConfig.senderId.length > 15) {
      toastMaker.makeToast(
        "Sender Id should be less than or equal to 15 characters.",
        {
          variant: "error",
        }
      );
      return;
    }
    if (!selectedEnv || selectedEnv.length === 0) {
      toastMaker.makeToast(
        "Please select an environment and load contacts from it!",
        {
          variant: "error",
        }
      );
      return;
    }
    if (!selectedList || selectedList.length === 0) {
      toastMaker.makeToast("Please select a list and load contacts from it!", {
        variant: "error",
      });
      return;
    }
    if (!contacts || contacts.length === 0) {
      toastMaker.makeToast(
        "Please ensure that atleast one contact exists to which SMS can be sent!",
        {
          variant: "error",
        }
      );
      return;
    }
    if (contactsWithPhone.length === 0 || contactsWithPhone.length > 50000) {
      toastMaker.makeToast(
        "Total number of contacts with phone numbers in the list should be between 0 and 50,000.",
        {
          variant: "error",
        }
      );
      return;
    }
    if (useTwoWayNumber && selectedTwoWayNumber === null) {
      toastMaker.makeToast("Please select a two-way SMS number to use!", {
        variant: "error",
      });
      return;
    }
    if (isMMS && bulkSMSConfig.subject == null) {
      toastMaker.makeToast("Please select subject to use!", {
        variant: "error",
      });
      return;
    }
    if (isMMS && bulkSMSConfig.file == null && bulkSMSConfig.url == null) {
      toastMaker.makeToast("Please select file to use!", {
        variant: "error",
      });
      return;
    }
    if (useTwoWayNumber && selectedTwoWayNumber === null) {
      toastMaker.makeToast("Please select a two-way SMS number to use!", {
        variant: "error",
      });
      return;
    }
    const result = window.confirm(
      "Are you sure you want to send this campaign? This will send SMS to all selected contacts."
    );
    if (!result) {
      return;
    }

    const formData = new FormData();
    formData.append("host", selectedEnv.host);
    formData.append("alias", selectedEnv.alias);
    formData.append("api_key", selectedEnv.api_key);
    formData.append("name", campaignName);
    formData.append("list_id", selectedList.id);
    formData.append("list_name", selectedList.name);
    formData.append("segment_id", selectedSegment.id);
    formData.append("segment_name", selectedSegment.name);
    formData.append("description", campaignDescription);
    formData.append("sender_id", bulkSMSConfig.senderId);
    formData.append("type", isMMS ? 'mms' : 'sms');
    formData.append("message", bulkSMSConfig.template
                                            .replace(Constants.unsubscribe_link_show, Constants.unsubscribe_link)
                                            .replace(`(${Constants.unsubscribe_link})`, `( ${Constants.unsubscribe_link} )`)
                                            .replace(`[${Constants.unsubscribe_link}]`, `[ ${Constants.unsubscribe_link} ]`)
                                          );
    formData.append("subject", bulkSMSConfig.subject);

    if(bulkSMSConfig && bulkSMSConfig?.file && bulkSMSConfig?.file?.length) {
      formData.append("file", bulkSMSConfig.file[0]);
    }
    formData.append("url", bulkSMSConfig.url);
    formData.append("is_2way", useTwoWayNumber ? 1 : 0);
    formData.append("add_credits", 0);
    formData.append("estimated_credits", 0);
    formData.append("longcode", selectedTwoWayNumber);

    setIsLoading(true);
    axios
      .post(
        Constants.getApiPathWithConfigId(Constants.aclistContactsSendSMSApiPath),
        // {
        //   host: selectedEnv.host,
        //   alias: selectedEnv.alias,
        //   api_key: selectedEnv.api_key,
        //   name: campaignName,
        //   list_id: selectedList.id,
        //   list_name: selectedList.xc,
        //   segment_id: selectedSegment.id,
        //   segment_name: selectedSegment.name,
        //   description: campaignDescription,
        //   sender_id: bulkSMSConfig.senderId,
        //   type: isMMS ? 'mms' : 'sms',
        //   message: bulkSMSConfig.template.replace(Constants.unsubscribe_link_show, Constants.unsubscribe_link),
        //   subject: bulkSMSConfig.subject,
        //   file: bulkSMSConfig.file,
        //   url: bulkSMSConfig.url,
        //   is_2way: useTwoWayNumber,
        //   add_credits: 0,
        //   estimated_credits: 0,
        //   longcode: selectedTwoWayNumber
        // }, 
        formData, {        
          headers: { 
            "Authorization": `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data"
          },
        })
      .then((resp) => {
        setIsLoading(false);
        toastMaker.makeToast("Successfully submitted campaign!", {
          variant: "success",
        });
        setShowEnd(true);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        let errmsg = "";
        if(error.response.status == 400) {
          errmsg = Object.keys(error.response.data.errors).map(e => error.response.data.errors[e][0]).join(', ')
        } else {
          errmsg = "Something went wrong, please try again latter"
        }
        
        toastMaker.makeToast(errmsg, {
          variant: "error",
        });
      });
  };

  const handleNext = () => {
    // logger.log("activeStep:", activeStep);
    stepData[activeStep].next();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCampaignName(null);
    setCampaignDescription(null);
    setLists([]);
    setSegments([])
    setSelectedEnv(null);
    setSelectedList(null);
    setSelectedSegment(null)
    setContacts([]);
    setbulkSMSConfig({
      senderId: null,
      template: null,
      file: null,
      url: null,
      subject: null
    });
    setShowEnd(false);
  };

  if (isLoading) {
    return <LoadingProgress />;
  }

  logger.log(activeStep);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
      <Grid item xs={12}> 
      <Box className="box-container">
      <div className="box-viewport-set" >
      <Header name={"Sms Campaign"} />
      <Induction
        name={props.name}
        content={
          <ul style={{ listStyleType: "circle" }}>
            <li>
              <p>Please follow the below steps to create your SMS Campaign.</p>
            </li>
          </ul>
        }
      />
      </div>
      {showEnd && (
        <Paper
          elevation={3}
          style={{
            margin: "0 auto",
            maxWidth: "700px",
            padding: "30px 10px",
            marginBottom: "100px",
          }}
        >
          <div>
            <Typography className={classes.instructions}>
              <h3>All done!</h3>
            </Typography>
            <Typography>
              Your bulk SMS Campaign has been sent. You can check the progress under the 'Campaign History' screen.
            </Typography>
            <div style={{ padding: "30px 10px", textAlign: "end" }} className="mb-button width-auto">
              <span
                className="c-button text-uppercase"
                disabled={activeStep === -1}
                style={{ marginRight: "10px" }}
                onClick={() => {
                  Constants.navigate(Constants.campaignhistory);
                }}
              >
                Go to Campaign History
              </span>
              <span
                className="c-button c-button--secondary text-uppercase"
                onClick={handleReset}
                style={{ marginRight: "10px" }}
              >
                Send another Campaign
              </span>
            </div>
          </div>
        </Paper>
      )}
      {!showEnd && (
        <div className="sms-campaign-section">
          <Paper className="box-viewport-set md-view" 
            elevation={3}
            style={{
              margin: "0 auto",
              maxWidth: "700px",
              padding: "10px",
              marginBottom: "10px",
            }}                                       
          >
            <div>
              <Typography className={classes.instructions}>
                Campaign Type
              </Typography>

              <form>
                <label>
                  Choose type of campaign
                  <div class="tooltip ml-2">
                      <HelpIcon />
                      <span class="tooltiptext">
                        MMS includes a subject line and an image—a powerful marketing tool for premium promotions sent to your contacts.
                      </span>
                    </div>
                </label>
                <div className="form-groups">
                  SMS<Switch
                    checked={isMMS}
                    onChange={() => setIsMMS(!isMMS)}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />MMS
                </div>
              </form>
            </div>
          </Paper>
          <Paper className="box-viewport-set md-view"
            elevation={3}
            style={{
              margin: "0 auto",
              maxWidth: "700px",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <div>
              <Typography className={classes.instructions}>
                Campaign Details
              </Typography>

              <form>
                <label>
                  Give your Campaign a name!
                  <div class="tooltip ml-2">
                    <HelpIcon />
                    <span class="tooltiptext">
                    This will also add a tag in AC. Keep it concise, specific to the campaign you are sending.
                    </span>
                  </div>
                </label>
                <div className="form-groups">
                  <input
                    id="campaignName"
                    value={campaignName}
                    onChange={(event) => {
                      setCampaignName(event.target.value);
                    }}
                    type="text"
                    className="form-controls"
                  />
                </div>
                <label>Campaign Description (optional)</label>
                <div className="form-groups">
                  <textarea
                    rows="4"
                    id="campaignDescription"
                    className="form-controls"
                    value={campaignDescription}
                    onChange={(event) => {
                      setCampaignDescription(event.target.value);
                    }}
                  />
                </div>
              </form>
            </div>
          </Paper>
          {isTwoWaySMSEnabled && (
            <Paper className="box-viewport-set md-view"
              elevation={3}
              style={{
                margin: "0 auto",
                maxWidth: "700px",
                padding: "10px",
                marginBottom: "10px",
              }}
            >
              <div>
                <Typography className={classes.instructions}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={useTwoWayNumber}
                          onChange={() => {
                            setUseTwoWayNumber(!useTwoWayNumber);
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Use a Two-Way SMS Number"
                    />
                  </FormGroup>
                  {useTwoWayNumber && (
                    <FormControl
                      component="fieldset"
                      style={{ marginLeft: "30px" }}
                    >
                      <RadioGroup
                        name="selectedTwoWayNumber"
                        value={selectedTwoWayNumber}
                        onChange={(event) => {
                          setSelectedTwoWayNumber(event.target.value);
                          setbulkSMSConfig({...bulkSMSConfig, senderId: event.target.value})
                        }}
                      >
                        {user &&
                        user?.configs &&
                        user?.configs[0] &&
                        user?.configs[0]?.longcodes &&
                        user?.configs[0]?.longcodes?.length &&
                        user?.configs[0]?.longcodes?.map((virtualNumber) => {
                            return (
                              <FormControlLabel
                                value={virtualNumber.number}
                                control={<Radio color="primary" />}
                                label={`+${virtualNumber.number} ${JSON.parse(virtualNumber?.credentials)?.label == undefined ? '' : ` - ${JSON.parse(virtualNumber?.credentials)?.label}`}`}
                                disabled={!useTwoWayNumber}
                              />
                            );
                          })}
                      </RadioGroup>
                    </FormControl>
                  )}
                </Typography>
              </div>
            </Paper>
          )}
          <Paper className="box-viewport-set md-view"
            elevation={3}
            style={{
              margin: "0 auto",
              maxWidth: "700px",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <div className="sms-details">
              <Typography className={classes.instructions}>
                SMS Details
              </Typography>
              <BulkSMSForm
                setbulkSMSConfig={setbulkSMSConfig}
                useTwoWayNumber={useTwoWayNumber}
                isMMS={isMMS}
              />
              
            </div>
          </Paper>
          <Paper className="box-viewport-set mb-100 md-view"
            elevation={3}
            style={{
              margin: "0 auto",
              maxWidth: "700px",
              padding: "10px",
              marginBottom: "60px",
            }}
          >
            <div style={{ margin: "0 auto", maxWidth: "700px" }} className="contact-details">
              <Typography className={classes.instructions}>
                Contact Details
              </Typography>
              {true && (
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  //connector={<QontoConnector />}
                >
                  {Object.keys(stepData).map((key) => {
                    const data = stepData[key];
                    return (
                      <Step key={data.title}>
                        <StepLabel className={classes.stepper}>
                          {data.title}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              )}
              
              <div>
                {isStepperLoading && <LoadingProgress />}
                {
                  <div style={{ margin: "0 auto", maxWidth: "700px" }}>
                    {getStepContent(activeStep)}
                    <div className="mb-button"
                      style={{
                        paddingTop: "30px",
                        paddingBottom: "20px",
                        textAlign: "end",
                      }}
                    >
                      {activeStep !== 0 && (
                        <span
                          className="c-button text-uppercase"
                          disabled={activeStep === -1}
                          style={{ marginRight: "10px" }}
                          onClick={handleBack}
                        >
                          Back
                        </span>
                      )}
                      {activeStep !== Object.keys(stepData).length - 1 && (
                        <span
                          className="c-button c-button--secondary text-uppercase"
                          onClick={handleNext}
                          style={{ marginRight: "10px" }}
                        >
                          Next
                        </span>
                      )}
                      {activeStep === Object.keys(stepData).length - 1 && (
                        <CampaignSMSPreview
                          contactsWithPhone={contactsWithPhone}
                          bulkSMSConfig={bulkSMSConfig}
                          useTwoWayNumber={useTwoWayNumber}
                          selectedTwoWayNumber={selectedTwoWayNumber}
                          isMMS={isMMS}
                        />
                      )}
                      {activeStep === Object.keys(stepData).length - 1 && (
                        <span
                          onClick={handleSubmitCampaign}
                          className="c-button c-button_secondary send-button"
                          style={{ marginLeft: "10px" }}
                        >
                          Send Campaign!
                        </span>
                      )}
                    </div>
                  </div>
                }
              </div>
            </div>
          </Paper>                      
        </div>
      )}
      </Box>  
      </Grid>
      </Grid>
    </div>
  );
}

// export default withRouter(HorizontalLabelPositionBelowStepper);
export default HorizontalLabelPositionBelowStepper;

